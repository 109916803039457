import React, { useEffect, useState } from "react";
import mainLogo from "../assets/Images/logo_open_challenge.svg";
import "../assets/Styles/AdminDashboard.css";
import { useNavigate } from "react-router-dom";
import AddAdminForm from "../components/AdminDashboard/AddAdminForm";
import { TbArrowBarRight } from "react-icons/tb";
import AdminNav from "../components/AdminDashboard/AdminNav";
import TournamentNav from "../components/AdminDashboard/TournamentNav"; // Importation de TournamentNav

function AdminDashboard() {
  const token = localStorage.getItem("token");
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [tournaments, setTournaments] = useState([]);
  const [activeTab, setActiveTab] = useState("Tournoi");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/tournaments`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch tournaments");
        }
        const data = await response.json();
        setTournaments(data);
      } catch (error) {
        console.error("Error fetching tournaments:", error);
      }
    };

    fetchTournaments();
  }, []);

  const handleCreate = async (tournament) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/tournament`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(tournament),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create tournament");
      }

      const newTournament = await response.json();
      setTournaments([...tournaments, newTournament]);
      setSelectedTournament(null);
    } catch (error) {
      console.error("Error creating tournament:", error);
    }
  };

  const handleEditSubmit = async (updatedTournament) => {
    console.log("Données du tournoi à mettre à jour:", updatedTournament);
    console.log("Token utilisé:", token);
    const { id } = updatedTournament;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/tournament/${id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedTournament),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Échec de la mise à jour du tournoi: ${errorData.message || response.statusText}`);
      }

      const updatedData = await response.json();
      setTournaments(tournaments.map((t) => (t.id === id ? updatedData : t)));
      setSelectedTournament(null);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du tournoi:", error.message);
      // Ajoutez ici un code pour afficher l'erreur à l'utilisateur
    }
  };

  const handleDelete = async (tournamentId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/tournament/${tournamentId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete tournament");
      }

      setTournaments(
        tournaments.filter((tournament) => tournament.id !== tournamentId)
      );
    } catch (error) {
      console.error("Error deleting tournament:", error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("token");
    navigate("/accueil");
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderActiveTab = () => {
    switch (activeTab) {
      case "Tournoi":
        return (
          <TournamentNav
            tournaments={tournaments}
            onCreate={handleCreate}
            onEdit={handleEditSubmit}
            onDelete={handleDelete}
            selectedTournament={selectedTournament}
            setSelectedTournament={setSelectedTournament}
          />
        );
      case "Admin":
        return(
          <AddAdminForm />
        )
      // Ajouter d'autres cas pour différents onglets ici
      default:
        return null;
    }
  };

  return (
    <div className="adminContainer">
      <img
        className="homeLogo"
        alt="logo_OC"
        src={mainLogo}
        onClick={() => navigate("/")}
      />
      <div>
        <div className="adminLogout" onClick={handleLogout}>
          <p>Déconnexion</p>
          <TbArrowBarRight className="logoutButton" size={24} color="#ff5353" />
        </div>

        <AdminNav activeTab={activeTab} handleTabClick={handleTabClick} />

        <div className="tabContent">{renderActiveTab()}</div>
        
      </div>
    </div>
  );
}

export default AdminDashboard;
