import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { TbChevronLeft } from "react-icons/tb";
import "./AdminTournamentMatchesDetails.css";

function AdminTournamentMatchesDetails() {
  const { matchId } = useParams();
  const [match, setMatch] = useState(null);
  const [homeTeamName, setHomeTeamName] = useState("");
  const [awayTeamName, setAwayTeamName] = useState("");
  const [homeTeamPlayers, setHomeTeamPlayers] = useState([]);
  const [awayTeamPlayers, setAwayTeamPlayers] = useState([]);
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    homeScore: 0,
    awayScore: 0,
    statusMatch: "",
    topScorer: "",
    matchDate: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMatchDetails = async () => {
      try {
        const matchResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/matches/${matchId}`
        );
        if (!matchResponse.ok) {
          throw new Error("Failed to fetch match details");
        }
        const matchData = await matchResponse.json();
        setMatch(matchData);
        setFormData({
          homeScore: matchData.homeScore,
          awayScore: matchData.awayScore,
          statusMatch: matchData.statusMatch,
          topScorer: matchData.topScorer || "",
          matchDate: matchData.matchDate
            ? new Date(matchData.matchDate).toISOString().slice(0, 16)
            : "",
        });

        // Fetch home team details and players
        const homeTeamResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/teams/${matchData.homeTeam}/admin`
        );
        if (!homeTeamResponse.ok) {
          throw new Error("Failed to fetch home team details");
        }
        const homeTeamData = await homeTeamResponse.json();
        setHomeTeamName(homeTeamData.name);
        setHomeTeamPlayers(homeTeamData.players || []);

        // Fetch away team details and players
        const awayTeamResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/teams/${matchData.awayTeam}/admin`
        );
        if (!awayTeamResponse.ok) {
          throw new Error("Failed to fetch away team details");
        }
        const awayTeamData = await awayTeamResponse.json();
        setAwayTeamName(awayTeamData.name);
        setAwayTeamPlayers(awayTeamData.players || []);
      } catch (error) {
        console.error("Error fetching match details:", error);
      }
    };

    fetchMatchDetails();
  }, [matchId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/matches/${matchId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            ...formData,
            matchDate: new Date(formData.matchDate).toISOString(),
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update match");
      }

      const updatedMatch = await response.json();
      setMatch(updatedMatch);

      setMessage("Match mis à jour.");
      setTimeout(() => {
        setMessage("");
      }, 5000);
    } catch (error) {
      console.error("Error updating match:", error);
      setMessage("Mise à jour échouée.");
      setTimeout(() => {
        setMessage("");
      }, 5000);
    }
  };

  if (!match) {
    return <div>Loading...</div>;
  }

  // Check if the date is valid, otherwise show "À venir"
  const displayDate = formData.matchDate
    ? !isNaN(new Date(formData.matchDate).getTime())
      ? new Date(formData.matchDate).toLocaleString()
      : "À venir"
    : "À venir";
  
  const displayStatus = formData.statusMatch;

  return (
    <div className="adminMatchDetailsContainer">
      <div className="backButtonContainer">
        <button className="backButton" onClick={() => navigate(-1)}>
          <TbChevronLeft />
        </button>
        <h2>Détails du match</h2>
      </div>
      <div className="matchDetails">
        <h3>
          {homeTeamName} vs {awayTeamName}
        </h3>
        <p>Date: {displayDate}</p>
        <p>Status: {displayStatus}</p>
      </div>

      <div className="matchForm">
        <h3>Modifier le match</h3>
        <form onSubmit={handleSubmit}>
          <div className="formGroup">
            <div>
              <label htmlFor="homeScore">
                Score
                <br /> {homeTeamName}
              </label>
              <input
                type="number"
                id="homeScore"
                name="homeScore"
                value={formData.homeScore}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label htmlFor="awayScore">
                Score <br /> {awayTeamName}
              </label>
              <input
                type="number"
                id="awayScore"
                name="awayScore"
                value={formData.awayScore}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="formGroup">
            <label htmlFor="matchDate">Date du match</label>
            <input
              type="datetime-local"
              id="matchDate"
              name="matchDate"
              value={formData.matchDate}
              onChange={handleChange}
              required
            />
          </div>
          <div className="formGroup">
            <label htmlFor="statusMatch">Statut du match</label>
            <select
              id="statusMatch"
              name="statusMatch"
              value={formData.statusMatch}
              onChange={handleChange}
              required
            >
              <option value="à venir">À venir</option>
              <option value="terminé">Terminé</option>
            </select>
          </div>
          <div className="formGroup">
            <label htmlFor="topScorer">Meilleur buteur</label>
            <select
              id="topScorer"
              name="topScorer"
              value={formData.topScorer}
              onChange={handleChange}
            >
              <option value="">Aucun</option>
              <optgroup label={`Joueurs de ${homeTeamName}`}>
                {homeTeamPlayers.map((player) => (
                  <option key={player.id} value={player.number}>
                    {player.firstName} {player.lastName} - N°{player.number}
                  </option>
                ))}
              </optgroup>
              <optgroup label={`Joueurs de ${awayTeamName}`}>
                {awayTeamPlayers.map((player) => (
                  <option key={player.id} value={player.number}>
                    {player.firstName} {player.lastName} - N°{player.number}
                  </option>
                ))}
              </optgroup>
            </select>
          </div>
          {message && <div className="message">{message}</div>}
          <button type="submit">Mettre à jour</button>
        </form>
      </div>
    </div>
  );
}

export default AdminTournamentMatchesDetails;
