import React from 'react'
import { TbChevronLeft } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
import './MonEquipeBanner.css'


function MonEquipeBanner() {
    const navigate = useNavigate();
    const handleBack = () =>{
      navigate("/")
    }
  return (
    <div className='monEquipeBannerContainer'>
        <TbChevronLeft className='backBtn' size={40} onClick={()=> handleBack()} />
        <p>Mon équipe</p>
    </div>
  )
}

export default MonEquipeBanner;