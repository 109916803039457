// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Conteneur général qui masque l'overflow */
.slider-container {
    overflow: hidden;
    width: 100%; /* Ajuster selon tes besoins */
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 460px;
    display: flex;
    flex-direction: column;
  }

  .slider-container h2{
    color: white;
    margin-bottom: 16px;
    margin-top: 100px;
  }
  
  /* Flexbox pour disposer les images en ligne et permettre le défilement */
  .slider-content {
    display: flex;
    width: max-content;
    animation: scrollSlider 10s linear infinite;
    padding: 12px 20px;
  }
  
  /* Chaque élément (logo de partenaire) */
  .slider-item {
    flex-shrink: 0;
    margin-right: 20px; /* Ajuster selon l'espace souhaité entre les images */

    
  }
  
  .partner-logo {
    width: 100px; /* Ajuster la taille des logos */
    height: auto;
    aspect-ratio: 1/1;
    object-position: center center;
    object-fit: cover;
    border-radius: 8px;
  }
  
  /* Animation des keyframes pour un défilement continu */
  @keyframes scrollSlider {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/UI/PartnersSlider.css"],"names":[],"mappings":"AAAA,4CAA4C;AAC5C;IACI,gBAAgB;IAChB,WAAW,EAAE,8BAA8B;IAC3C,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;EACnB;;EAEA,yEAAyE;EACzE;IACE,aAAa;IACb,kBAAkB;IAClB,2CAA2C;IAC3C,kBAAkB;EACpB;;EAEA,wCAAwC;EACxC;IACE,cAAc;IACd,kBAAkB,EAAE,qDAAqD;;;EAG3E;;EAEA;IACE,YAAY,EAAE,gCAAgC;IAC9C,YAAY;IACZ,iBAAiB;IACjB,8BAA8B;IAC9B,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA,uDAAuD;EACvD;IACE;MACE,wBAAwB;IAC1B;IACA;MACE,2BAA2B;IAC7B;EACF","sourcesContent":["/* Conteneur général qui masque l'overflow */\r\n.slider-container {\r\n    overflow: hidden;\r\n    width: 100%; /* Ajuster selon tes besoins */\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    max-width: 460px;\r\n    display: flex;\r\n    flex-direction: column;\r\n  }\r\n\r\n  .slider-container h2{\r\n    color: white;\r\n    margin-bottom: 16px;\r\n    margin-top: 100px;\r\n  }\r\n  \r\n  /* Flexbox pour disposer les images en ligne et permettre le défilement */\r\n  .slider-content {\r\n    display: flex;\r\n    width: max-content;\r\n    animation: scrollSlider 10s linear infinite;\r\n    padding: 12px 20px;\r\n  }\r\n  \r\n  /* Chaque élément (logo de partenaire) */\r\n  .slider-item {\r\n    flex-shrink: 0;\r\n    margin-right: 20px; /* Ajuster selon l'espace souhaité entre les images */\r\n\r\n    \r\n  }\r\n  \r\n  .partner-logo {\r\n    width: 100px; /* Ajuster la taille des logos */\r\n    height: auto;\r\n    aspect-ratio: 1/1;\r\n    object-position: center center;\r\n    object-fit: cover;\r\n    border-radius: 8px;\r\n  }\r\n  \r\n  /* Animation des keyframes pour un défilement continu */\r\n  @keyframes scrollSlider {\r\n    0% {\r\n      transform: translateX(0);\r\n    }\r\n    100% {\r\n      transform: translateX(-50%);\r\n    }\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
