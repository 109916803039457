// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournamentDetailsContainer {
  width: 100%;
  min-height: 100vh;
  background: #0c0b20;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 40px 0px;
  max-width: 100%; /* Evite de dépasser le viewport */
}

.tournamentDetailsContainer > div{
  width: 100%;
  max-width: 500px;
}

.tournamentDetailsContainer-webkit-scrollbar-thumb{
  background: #c25656;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.tournamentDetailsBanner {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 8px 20px;
  gap: 16px;
}

.tournamentDetailsBanner #logo img {
  width: 100px;
}

.tournamentDetailsBanner #nameDate {
    display: flex;
    flex-direction: column;
    gap: 8px;
  color: #fff;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 12px */
}

.tournamentDetailsBanner #nameDate h1 {
    color: #fff;
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 12px */
    text-transform: uppercase;
  }
`, "",{"version":3,"sources":["webpack://./src/assets/Styles/TournamentDetails.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,iBAAiB;EACjB,eAAe,EAAE,kCAAkC;AACrD;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,wBAAwB;EACxB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,YAAY;AACd;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;EACV,WAAW;EACX,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;AAC9B;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB,EAAE,SAAS;IAC5B,yBAAyB;EAC3B","sourcesContent":[".tournamentDetailsContainer {\r\n  width: 100%;\r\n  min-height: 100vh;\r\n  background: #0c0b20;\r\n  color: white;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  gap: 10px;\r\n  padding: 40px 0px;\r\n  max-width: 100%; /* Evite de dépasser le viewport */\r\n}\r\n\r\n.tournamentDetailsContainer > div{\r\n  width: 100%;\r\n  max-width: 500px;\r\n}\r\n\r\n.tournamentDetailsContainer-webkit-scrollbar-thumb{\r\n  background: #c25656;\r\n  border: 0px none #ffffff;\r\n  border-radius: 50px;\r\n}\r\n.tournamentDetailsBanner {\r\n  width: 100%;\r\n  display: flex;\r\n  align-items: center;\r\n  position: relative;\r\n  padding: 8px 20px;\r\n  gap: 16px;\r\n}\r\n\r\n.tournamentDetailsBanner #logo img {\r\n  width: 100px;\r\n}\r\n\r\n.tournamentDetailsBanner #nameDate {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 8px;\r\n  color: #fff;\r\n  font-family: Lato;\r\n  font-size: 16px;\r\n  font-style: normal;\r\n  font-weight: 700;\r\n  line-height: 100%; /* 12px */\r\n}\r\n\r\n.tournamentDetailsBanner #nameDate h1 {\r\n    color: #fff;\r\n    font-family: Lato;\r\n    font-size: 24px;\r\n    font-style: normal;\r\n    font-weight: 700;\r\n    line-height: 100%; /* 12px */\r\n    text-transform: uppercase;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
