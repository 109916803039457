// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuAdminContainer{
    display: flex;
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 100px;
    padding: 12px 20px;
    justify-content: center;
    align-items: flex-start;
    gap: 18px;
    flex-shrink: 0;
    border-radius: 80px 80px 0px 0px;
    border: 2px solid var(--degra-1, #6E813F);
    background: #FFF;
    box-shadow: 0px -20px 28.2px 0px rgba(0, 0, 0, 0.50);
    max-width: 500px;
}

.menuAdminContainer ul{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
    font-family: Lato;
    font-size: 12px;
    display: flex;
    list-style: none;
    font-weight: 700;
    color: #0C0B20;
}

.menuAdminContainer ul li{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/MenuAdmin.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,eAAe;IACf,SAAS;IACT,aAAa;IACb,kBAAkB;IAClB,uBAAuB;IACvB,uBAAuB;IACvB,SAAS;IACT,cAAc;IACd,gCAAgC;IAChC,yCAAyC;IACzC,gBAAgB;IAChB,oDAAoD;IACpD,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,6BAA6B;IAC7B,mBAAmB;IACnB,iBAAiB;IACjB,eAAe;IACf,aAAa;IACb,gBAAgB;IAChB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,QAAQ;AACZ","sourcesContent":[".menuAdminContainer{\r\n    display: flex;\r\n    width: 100%;\r\n    position: fixed;\r\n    bottom: 0;\r\n    height: 100px;\r\n    padding: 12px 20px;\r\n    justify-content: center;\r\n    align-items: flex-start;\r\n    gap: 18px;\r\n    flex-shrink: 0;\r\n    border-radius: 80px 80px 0px 0px;\r\n    border: 2px solid var(--degra-1, #6E813F);\r\n    background: #FFF;\r\n    box-shadow: 0px -20px 28.2px 0px rgba(0, 0, 0, 0.50);\r\n    max-width: 500px;\r\n}\r\n\r\n.menuAdminContainer ul{\r\n    display: flex;\r\n    width: 100%;\r\n    height: 100%;\r\n    justify-content: space-evenly;\r\n    align-items: center;\r\n    font-family: Lato;\r\n    font-size: 12px;\r\n    display: flex;\r\n    list-style: none;\r\n    font-weight: 700;\r\n    color: #0C0B20;\r\n}\r\n\r\n.menuAdminContainer ul li{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    gap: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
