import React, { useState, useEffect } from 'react';
import './Finales.css';
import MatchesCard from './Matches/MatchesCard'; // Assuming you want to reuse MatchesCard to display the matches
import { useParams } from 'react-router-dom';

function Finales() {
  const [matches, setMatches] = useState([]); // Initialiser avec un tableau vide
  const [filter, setFilter] = useState('Demi-finale'); // État pour gérer le filtre par défaut
  const { id } = useParams(); // Récupérer l'ID du tournoi depuis les paramètres de l'URL

  useEffect(() => {
    // Fonction pour récupérer les matchs depuis l'API
    const fetchMatches = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/tournament/${id}/matches`);
        const data = await response.json();

        if (response.ok) {
          // Filtrer les matchs par catégorie ("Demi-finale" et "Finale")
          const filteredData = data.filter(match => match.category === 'Demi-finale' || match.category === 'Finale');
          setMatches(filteredData || []); // S'assurer que data.matches est bien un tableau
          
        } else {
          console.error('Failed to fetch matches:', data.message);
          setMatches([]); // En cas d'erreur, définir matches comme tableau vide
        }
      } catch (error) {
        console.error('Error fetching matches:', error);
        setMatches([]); // En cas d'erreur, définir matches comme tableau vide
      }
    };

    fetchMatches();
  }, [id]);

  // Filtrer les matchs en fonction du filtre sélectionné
  const filteredMatches = matches.filter(match => match.category === filter);

  return (
    <div className='finalesContainer'>
      {/* Boutons pour filtrer les matchs */}
      <div className="filterButtons">
        <button
          className={`semiFinaleBtn ${filter === 'Demi-finale' ? 'active' : ''}`}
          onClick={() => setFilter('Demi-finale')}
        >
          Demi-finale
        </button>
        <button
          className={`finaleBtn ${filter === 'Finale' ? 'active' : ''}`}
          onClick={() => setFilter('Finale')}
        >
          Finale
        </button>
      </div>

      {/* Afficher les matchs filtrés */}
      {filteredMatches.length > 0 ? (
        filteredMatches.map((match) => (
          <MatchesCard key={match.id} match={match} /> // Passer les données de chaque match en tant que prop au composant MatchesCard
        ))
      ) : (
        <p>Aucun match trouvé.</p> // Message à afficher si aucun match n'est trouvé
      )}
    </div>
  );
}

export default Finales;
