// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournamentNavContainer{
    width: 100%;
}

.tournamentNavContainer>button{
    width: 100%;
    padding: 16px 8px;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    background: transparent;
    color: #C3D32C;
    border: 1px solid #C3D32C;
}`, "",{"version":3,"sources":["webpack://./src/components/AdminDashboard/TournamentNav.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,uBAAuB;IACvB,cAAc;IACd,yBAAyB;AAC7B","sourcesContent":[".tournamentNavContainer{\r\n    width: 100%;\r\n}\r\n\r\n.tournamentNavContainer>button{\r\n    width: 100%;\r\n    padding: 16px 8px;\r\n    border-radius: 8px;\r\n    border: none;\r\n    font-size: 16px;\r\n    background: transparent;\r\n    color: #C3D32C;\r\n    border: 1px solid #C3D32C;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
