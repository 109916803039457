import React, { useState } from 'react';
import './AddAdminForm.css';

function AddAdminForm() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const token = localStorage.getItem('token'); // Récupérer le token depuis le localStorage
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/register-admin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Passer le token dans l'en-tête Authorization
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        // Admin enregistré avec succès
        console.log('Admin enregistré avec succès');
        // Réinitialiser le formulaire
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          phone: ''
        });
      } else {
        const data = await response.json();
        // Gérer les erreurs
        console.log(`Erreur: ${data.message}`);
      }
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement de l\'admin:', error);
    }
  };

  return (
    <div className='addAdminContainer'>
      <h2>Enregistrer un nouvel admin</h2>
      <form onSubmit={handleSubmit} className='addAdminForm'>
        <div className='formGroup'>
          <label htmlFor='firstName'>Nom</label>
          <input
            type='text'
            id='firstName'
            name='firstName'
            value={formData.firstName}
            onChange={handleChange}
            required
          />
        </div>

        <div className='formGroup'>
          <label htmlFor='lastName'>Prénom</label>
          <input
            type='text'
            id='lastName'
            name='lastName'
            value={formData.lastName}
            onChange={handleChange}
            required
          />
        </div>

        <div className='formGroup'>
          <label htmlFor='email'>Email</label>
          <input
            type='email'
            id='email'
            name='email'
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div className='formGroup'>
          <label htmlFor='password'>Mot de passe</label>
          <input
            type='password'
            id='password'
            name='password'
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>

        <div className='formGroup'>
          <label htmlFor='phone'>Numéro de téléphone</label>
          <input
            type='tel'
            id='phone'
            name='phone'
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>

        <button type='submit'>Enregistrer</button>
      </form>
    </div>
  );
}

export default AddAdminForm;
