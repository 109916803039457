import React, { useState } from 'react';
import '../assets/Styles/Verification.css';
import DetailsBanner from '../components/Tournament/DetailsBanner';
import { useSearchParams, useNavigate } from 'react-router-dom';

function Verification() {
    const [queryParams] = useSearchParams();
    const email = queryParams.get("email");
    const [code, setCode] = useState('');  // État pour stocker le code entré
    const [message, setMessage] = useState('');  // État pour stocker les messages de succès ou d'erreur
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/validate-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, code }),  // Envoyer email et code
            });

            const data = await response.json();

            if (response.ok) {
                setMessage("Votre compte a été validé avec succès !");
                // Rediriger vers la page de login après la validation
                setTimeout(() => {
                    navigate('/login');
                }, 2000);  // Redirige après 2 secondes
            } else {
                setMessage("Erreur : " + data.message);
            }
        } catch (error) {
            setMessage("Une erreur est survenue : " + error.message);
        }
    };

    if (email) {
        return (
            <div className='verificationContainer'>
                <DetailsBanner />
                <div className='verificationContent'>
                    <p>Vous devez d'abord valider votre compte.</p>
                    <p>Veuillez entrer le code reçu par mail lors de votre inscription.</p>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="code">Code</label>
                        <input 
                            type="text" 
                            value={code} 
                            onChange={(e) => setCode(e.target.value)} 
                            required 
                        />
                        <button type="submit">Valider mon compte</button>
                    </form>
                    {message && <p>{message}</p>}
                </div>
            </div>
        )
    } else {
        return <p>Email non trouvé.</p>;
    }
}

export default Verification;
