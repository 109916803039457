import React, { useState } from "react";
import "../assets/Styles/ResendCode.css"; // Assurez-vous que le chemin vers votre fichier CSS est correct
import DetailsBanner from '../components/Tournament/DetailsBanner';

function ResendCode() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [counter, setCounter] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Réinitialise le message d'erreur
    setMessage(""); // Réinitialise le message de succès

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/resend-code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage("Nouveau code envoyé par mail");
        setCounter(60); // Démarre le compteur à 60 secondes
        setTimeout(() => {
          setMessage(""); // Efface le message de succès après 5 secondes
        }, 5000);
      } else {
        throw new Error("Échec de l'envoi du code");
      }
    } catch (error) {
      if(error.status = 401){
        setError("Compte déjà vérifié");
      }else{
        setError("Erreur lors de l'envoi du code");
      }
      setTimeout(() => {
        setError(""); // Efface le message d'erreur après 5 secondes
      }, 5000);
    }
  };

  // Démarre le compteur
  React.useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setInterval(() => {
        setCounter((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <div className="resendCodeContainer">
      <DetailsBanner />
      <div className="resendCodeContent">
      <h2>Renvoyer le code de vérification</h2>
      <form className="resendCodeForm" onSubmit={handleSubmit}>
        <label htmlFor="email">Adresse e-mail</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" disabled={counter > 0}>
          {counter > 0 ? `Réessayer dans ${counter}s` : "Envoyer"}
        </button>
        {message && <div className="message">{message}</div>}
        {error && <div className="error">{error}</div>}
      </form>
    </div>
    </div>
  );
}

export default ResendCode;
