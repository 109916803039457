import React from "react";
import "./TournamentDetailsCard.css";
import { easeInOut, motion } from "framer-motion"

function TournamentDetailsCard({ additionalInfo }) {
  return (
    <motion.div variants={{
      hidden: {opacity:0, y: 50},
      visible: {opacity: 1, y: 0}
    }} initial="hidden" animate="visible" transition={{ease: easeInOut,duration: 0.3, delay: 0.9}} className="tournamentDetailsCardContainer">
      <p>Détails</p>
      <div className="tournamentDetailsCardSlider">
        <div className="tournamentDetailsCardWraper">
          <ul>
            <li>
              <p>Nombre d'équipes</p>
              <span>
                {additionalInfo ? additionalInfo.teamCount : "Non défini"}
              </span>
            </li>
            <li>
              <p>Nombre de poules</p>
              <span>
                {additionalInfo ? additionalInfo.groupCount : "Non défini"}
              </span>
            </li>
            <li>
              <p>Qualification</p>
              <span>
                {additionalInfo
                  ? additionalInfo.qualificationType
                  : "Non défini"}
              </span>
            </li>
            <li>
              <p>Temps de jeu (min)</p>
              <span>
                {additionalInfo ? additionalInfo.playtime : "Non défini"}
              </span>
            </li>
            <li>
              <p>Inscription</p>
              <span>
                {additionalInfo ? additionalInfo.inscriptionType : "Non défini"}
              </span>
            </li>
          </ul>
        </div>
        <div className="tournamentDetailsCardWraper">
          <ul>
            <li>
              <p>Points par victoire</p>
              <span>
                {additionalInfo
                  ? `${additionalInfo.victoryPoints} pts`
                  : "Non défini"}
              </span>
            </li>
            <li>
              <p>Match de poules</p>
              <span>
                {additionalInfo
                  ? additionalInfo.rankingMatch
                    ? "Oui"
                    : "Non"
                  : "Non défini"}
              </span>
            </li>
            <li>
              <p>Temps de pause (min)</p>
              <span>
                {additionalInfo ? additionalInfo.breaktime : "Non défini"}
              </span>
            </li>
            <li>
              <p>Nombre de remplacements</p>
              <span>
                {additionalInfo
                  ? additionalInfo.replacementCount
                  : "Non défini"}
              </span>
            </li>
            <li>
              <p>Genre</p>
              <span>
                {additionalInfo ? additionalInfo.gender : "Non défini"}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </motion.div>
  );
}

export default TournamentDetailsCard;
