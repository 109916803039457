import React from 'react'
import "./DetailsBanner.css"
import { useNavigate } from 'react-router-dom';
import { TbChevronLeft } from "react-icons/tb";
import { easeInOut, motion } from "framer-motion"


function DetailsBanner() {
  const navigate = useNavigate();
    const handleBack = () =>{
      navigate(-1)
    }
  return (
    <motion.div variants={{
      hidden: {opacity:0, x: 75},
      visible: {opacity: 1, x: 0}
    }} initial="hidden" animate="visible" transition={{ease: easeInOut,duration: 0.3, delay: 0.25}} className='detailsBannerContainer'>
      <TbChevronLeft className='backBtn' size={40} onClick={()=> handleBack()} />
      <p>OPEN CHALLENGE</p>
    </motion.div>
  )
}

export default DetailsBanner