import React from 'react';
import "../assets/Styles/Infos.css";
import mainLogo from "../assets/Images/logo_open_challenge.svg"; // Logo principal de l'application
import { TbChevronLeft } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

function Infos() {
  const navigate = useNavigate();
  return (
    <div className="infosContainer">
      <div className="infosWrapper">
        <div className="loginBanner">
          {/* Affiche le logo principal */}
          <TbChevronLeft className='backBtn' size={40} onClick={() => { navigate(-1) }} />
          <img className="mainLogo" alt="logo_OC" src={mainLogo} />
        </div>
        <h2>Informations complémentaires</h2>
        {/* Ajout des informations sous le h2 */}
        <p>Inscription : 105 euros par équipe</p>
        <p>Nombre de joueurs : 4 tableaux</p>
        <p>Entre 12 et 20 équipes par tableau</p>
        <p>5 joueurs minimum et 7 joueurs maximum par équipe</p>
        <p>Temps de jeu : 10 minutes par match (pas de mi-temps)</p>
        <p>Temps de pause : 12h à 14h</p>
      </div>
    </div>
  );
}

export default Infos;
