import React from 'react';
import './PlayerGrid.css';
import { TbCirclePlus } from "react-icons/tb";
import shirt from '../../assets/Images/shirt.svg';

function PlayerGrid({ players, onPlayerClick, onAddPlayerClick }) {
  const maxPlayers = 7; // Nombre maximum de joueurs
  const playerSlots = [...players];

  // Complétez avec des `null` pour les emplacements manquants
  while (playerSlots.length < maxPlayers) {
    playerSlots.push(null);
  }

  const formatPlayerName = (firstName, lastName) => {
    const initial = lastName ? lastName.charAt(0).toUpperCase() : '';
    return `${firstName}.${initial}`;
  };

  return (
    <div className="playerGridContainer">
      <p>Toucher un joueur pour ajouter / modifier ou supprimer</p>
      <div className="playerGrid">
        {playerSlots.map((player, index) => (
          <div 
            key={index} 
            className="playerCard" 
            onClick={() => player ? onPlayerClick(player.id) : onAddPlayerClick()}
          >
            {player ? (
              <>
                <div className='shirtNumber'>
                  <img src={shirt} alt="Player Shirt" className="playerShirt" />
                  <div className="playerNumber">{player.number}</div>
                </div>
                <div className="playerName">
                  {formatPlayerName(player.firstName, player.lastName)}
                </div>
              </>
            ) : (
              <>
                <TbCirclePlus size={48} className="addIcon" />
                <div className="playerName">Joueur {index + 1}</div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default PlayerGrid;
