// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infosContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background: #0C0B20;
    background-size: cover;
    align-items: center;
}

.infosWrapper{
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    padding: 12px 20px;
    max-width: 500px;
}

.infosWrapper h2,p{
    color: white;
}

.backBtn{
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/assets/Styles/Infos.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,iBAAiB;IACjB,mBAAmB;IACnB,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,WAAW;IACX,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".infosContainer {\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 100%;\r\n    min-height: 100vh;\r\n    background: #0C0B20;\r\n    background-size: cover;\r\n    align-items: center;\r\n}\r\n\r\n.infosWrapper{\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 32px;\r\n    width: 100%;\r\n    padding: 12px 20px;\r\n    max-width: 500px;\r\n}\r\n\r\n.infosWrapper h2,p{\r\n    color: white;\r\n}\r\n\r\n.backBtn{\r\n    color: white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
