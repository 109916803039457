import React, { useEffect, useState } from 'react';
import { TbUser } from 'react-icons/tb';
import './TournamentTeams.css';
import { easeInOut, motion } from "framer-motion"

function TournamentTeams({ tournament }) {
  const [teams, setTeams] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const tournamentId = tournament.id;

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    fetch(`${apiUrl}/tournament/${tournamentId}/teams`)
      .then((response) => response.json())
      .then((data) => setTeams(data))
      .catch((error) => console.error("Error fetching tournament:", error));

  }, [tournamentId]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredTeams = teams.filter(team =>
    team.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // console.log(filteredTeams);

  return (
    <motion.div variants={{
      hidden: {opacity:0, y: 50},
      visible: {opacity: 1, y: 0}
    }} initial="hidden" animate="visible" transition={{ease: easeInOut,duration: 0.3, delay: 1.1}} className='tournamentTeamsContainer'>
      <div style={{display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'space-between'}}>
        <p>Équipes</p>
        <div className="searchContainer">
          <input
            type="text"
            placeholder="Rechercher une équipe..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <ul>
        {filteredTeams.map(team => (
          <li key={team.id}>
            <div className='teamPicture'>
              {team.logo ? (
                <img src={`${process.env.REACT_APP_API_URL}/${team.logo}`} alt={team.name} className="teamLogo" />
              ) : (
                <TbUser size={24} />
              )}
            </div>
            {team.name}
          </li>
        ))}
      </ul>
    </motion.div>
  );
}

export default TournamentTeams;
