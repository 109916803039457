import React from 'react'
import { TbChevronLeft } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
import './MonCompteBanner.css'
function MonCompteBanner() {
    const navigate = useNavigate();
    const handleBack = () =>{
      navigate('/')
    }
  return (
    <div className='monCompteBannerContainer'>
        <TbChevronLeft className='backBtn' size={40} onClick={()=> handleBack()} />
        <p>Mon compte</p>
    </div>
  )
}

export default MonCompteBanner