import React, { useState, useEffect } from "react";
import "./TournamentInfosCard.css";
import { TbMapPin, TbUsersGroup, TbGiftCard } from "react-icons/tb";
import RewardsCard from "./RewardsCard";
import { useNavigate } from 'react-router-dom';
import { easeInOut, motion } from "framer-motion";

function TournamentInfosCard({ tournament }) {
  const [rewards, setRewards] = useState([]);
  const tournamentId = tournament.id;
  const navigate = useNavigate();

  const handleOverview = ({ tournamentId }) => {
    navigate(`/tournament/${tournamentId}/overview`);
  };

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    fetch(`${apiUrl}/tournament/${tournamentId}/rewards`)
      .then((response) => response.json())
      .then((data) => setRewards(data))
      .catch((error) => console.error("Error fetching tournaments:", error));
  }, [tournamentId]);

  return (
    <motion.div
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate="visible"
      transition={{ ease: easeInOut, duration: 0.3, delay: 0.7 }}
      className="tournamentInfosCardContainer"
    >
      <p>Informations</p>
      <div className="tournamentInfosCardWraper">
        <div className="tournamentInfosCardTop">
          <ul>
            <li>
              <TbMapPin size={30} /> {tournament.location}
            </li>
            <li>
              <TbUsersGroup size={30} /> {tournament.matchType}
            </li>
          </ul>
        </div>
        <div className="tournamentInfosCardLots">
          <p>
            <TbGiftCard size={30} />
            Lots
          </p>
          <div className="tournamentInfosCardSlider">
            {/* Vérification si rewards est un tableau avant d'utiliser map */}
            {Array.isArray(rewards) && rewards.length > 0 ? (
              rewards.map((reward) => <RewardsCard key={reward.id} />)
            ) : (
              <p>Aucun lot disponible.</p>
            )}
          </div>
        </div>
        <button onClick={() => handleOverview({ tournamentId })}>
          Accéder au tournoi
        </button>
      </div>
    </motion.div>
  );
}

export default TournamentInfosCard;
