import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../assets/Styles/TournamentDetails.css";
import DetailsBanner from "../components/Tournament/DetailsBanner";
import mainLogo from "../assets/Images/logo_open_challenge.svg";
import TournamentInfosCard from "../components/Tournament/TournamentInfosCard";
import TournamentDetailsCard from "../components/Tournament/TournamentDetailsCard";
import TournamentTeams from "../components/Tournament/TournamentTeams";
import ErrorPage from "../components/UI/ErrorPage";
import { easeInOut, motion } from "framer-motion"
import PartnersSlider from "../components/UI/PartnersSlider";


function TournamentDetails() {
  // Extraction de l'ID du tournoi depuis les paramètres de l'URL
  const { id } = useParams();

  // États pour gérer les données du tournoi et les erreurs éventuelles
  const [tournament, setTournament] = useState(null);
  const [error, setError] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useState(null);

  // Utilisation de useEffect pour récupérer les détails du tournoi lors du chargement du composant
  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
  
    // Fetch pour récupérer les détails du tournoi
    fetch(`${apiUrl}/tournament/${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Tournament not found');
        }
        return response.json();
      })
      .then((data) => {
        setTournament(data); // Stocker les données du tournoi dans l'état
  
        // Après avoir récupéré le tournoi, effectuer un fetch pour les informations additionnelles
        return fetch(`${apiUrl}/tournament/${id}/additional-info`);
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Additional info not found');
        }
        return response.json();
      })
      .then((additionalInfo) => {
        setAdditionalInfo(additionalInfo); // Stocker les informations additionnelles dans l'état
      })
      .catch((error) => setError(error.message)); // Gérer les erreurs
  }, [id]);

  // Gestion des erreurs : affiche une page d'erreur si une erreur est survenue
  if (error) {
    return <ErrorPage />;
  }

  // Affichage d'un écran de chargement tant que les données ne sont pas encore disponibles
  if (!tournament) {
    return <div className="loadingScreen">Loading...</div>;
  }

  return (
    <div className="tournamentDetailsContainer">
      {/* Affichage de la bannière du tournoi */}
      <DetailsBanner />

      <PartnersSlider/>

      {/* Section d'en-tête avec le logo et le nom du tournoi */}
      <motion.div variants={{
        hidden: {opacity:0, y: 5},
        visible: {opacity: 1, y: 0}
      }} initial="hidden" animate="visible" transition={{ease: easeInOut,duration: 0.3, delay: 0.55}} className="tournamentDetailsBanner">
        <div id="logo">
          <img src={mainLogo} alt="Open Challenge Logo" />
        </div>
        <div id="nameDate">
          <h1>{tournament.name}</h1>
          <p>
            {new Date(tournament.date).toLocaleDateString("fr-FR", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
        </div>
      </motion.div>

      {/* Affichage des différentes cartes d'informations et détails du tournoi */}
      
      <TournamentInfosCard tournament={tournament} />
      <TournamentDetailsCard additionalInfo={additionalInfo} />
      <TournamentTeams tournament={tournament} />
    </div>
  );
}

export default TournamentDetails;
