import React, { useEffect, useState } from 'react';
import './PlayerPopup.css';
import { TbUser, TbPhone, TbMail, TbCalendar, TbShirt, TbCircleX } from "react-icons/tb";

function PlayerPopup({ player, onClose, onSave, onDelete, isCaptain }) {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    number: '',
    age: '',
    address: '',
    phoneNumber: '',
    tshirtSize: '',
  });

  useEffect(() => {
    if (player) {
      setFormData({
        firstName: player.firstName || '',
        lastName: player.lastName || '',
        number: player.number || '',
        age: player.age || '',
        address: player.address || '',
        phoneNumber: player.phoneNumber || '',
        tshirtSize: player.tshirtSize || '',
      });
    }
  }, [player]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  const handleDeleteClick = () => {
    const confirmDelete = window.confirm("Êtes-vous sûr de vouloir supprimer ce joueur ?");
    if (confirmDelete) {
      onDelete(player.id);
    }
  };

  return (
    <div className="playerPopup">
      <div className="playerPopupContent">
        <h2 className="playerTitle">Modifier un joueur</h2>
        <form onSubmit={handleSubmit}>
          <div className="inputGroup">
            <div className="inputWithIcon">
              <TbUser size={24} />
              <input
                type="text"
                name="firstName"
                placeholder="Nom"
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div className="inputWithIcon">
              <TbUser size={24} />
              <input
                type="text"
                name="lastName"
                placeholder="Prénom"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="inputGroup">
            <div className="inputWithIcon">
              <TbPhone size={24} />
              <input
                type="text"
                name="phoneNumber"
                placeholder="Téléphone"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </div>
            <div className="inputWithIcon">
              <TbMail size={24} />
              <input
                type="text"
                name="address"
                placeholder="Adresse e-mail"
                value={formData.address}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="inputGroup">
            <div className="inputWithIcon">
              <TbCalendar size={24} />
              <input
                type="number"
                name="age"
                placeholder="Âge"
                value={formData.age}
                onChange={handleChange}
                min="0"
                max="99"
              />
            </div>
            <div className="inputWithIcon">
              <TbShirt size={24} />
              <input
                type="number"
                name="number"
                placeholder="N° du maillot"
                value={formData.number}
                onChange={handleChange}
                min="0"
                max="99"
              />
            </div>
          </div>
          <div className="inputWithIcon">
            <TbShirt size={24} />
            <select
              name="tshirtSize"
              value={formData.tshirtSize}
              onChange={handleChange}
            >
              <option value="">Taille de T-shirt</option>
              <option value="S">S</option>
              <option value="M">M</option>
              <option value="L">L</option>
              <option value="XL">XL</option>
            </select>
          </div>
          <button className="saveButton" type="submit">ENREGISTRER</button>
        </form>
        {!isCaptain && (
          <button className="deleteButton" onClick={handleDeleteClick}>Supprimer joueur</button>
        )}
        <TbCircleX size={32} className="closeButton" onClick={onClose}/>
      </div>
    </div>
  );
}

export default PlayerPopup;
