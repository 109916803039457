import React from 'react'
import './RewardsCard.css'
import rewardLogo from '../../assets/Images/rewards.png'

function RewardsCard() {
  return (
    <div className='rewardCardContainer'>
        <img src={rewardLogo} alt="" />
    </div>
  )
}

export default RewardsCard