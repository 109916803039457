import React, { useEffect, useState } from "react";
import './AdminNav.css';

function AdminNav({ activeTab, handleTabClick }) {
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  // Vérifie le rôle de l'utilisateur lorsqu'il charge le composant
  useEffect(() => {
    const checkUserRole = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/check-role`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user role');
        }

        const data = await response.json();
        setIsSuperAdmin(data.role === 'superadmin'); // Met à jour l'état si le rôle est superadmin
      } catch (error) {
        console.error('Error fetching user role:', error);
      }
    };

    checkUserRole();
  }, []);

  return (
    <div className="adminNavContainer">
      <div className="adminNavTabs">
        <div
          className={`adminNavItem ${activeTab === "Tournoi" ? "active" : ""}`}
          onClick={() => handleTabClick("Tournoi")}
        >
          Tournoi
        </div>
        {isSuperAdmin && ( // Affiche l'onglet Admin uniquement si l'utilisateur est superadmin
          <div
            className={`adminNavItem ${activeTab === "Admin" ? "active" : ""}`}
            onClick={() => handleTabClick("Admin")}
          >
            Admin
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminNav;
