import React from 'react';
import { motion } from 'framer-motion';

const pageTransition = {
  initial: { opacity: 0 },
  animate: { opacity: 1},
  exit: { opacity: 0 },
};

const pageTransitionTiming = {
  duration: 500,
  ease: "easeInOut",
};

const PageTransition = ({ children }) => {
  return (
    <>
      <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={{pageTransition}}
        transition={pageTransitionTiming}
        style={{ position: "absolute", width: "100vw", height:"100vh" }}
      >
        {children}
      </motion.div>
    </>
  );
};

export default PageTransition;
