import React, { useState, useEffect } from 'react';
import './Matchs.css'
import MatchesCard from './Matches/MatchesCard';
import { useParams } from 'react-router-dom';

function Matchs() {
  const [matches, setMatches] = useState([]); // Initialiser avec un tableau vide
  const [filter, setFilter] = useState('à venir'); // État pour gérer le filtre
  const { id } = useParams(); // Récupérer l'ID du tournoi depuis les paramètres de l'URL

  useEffect(() => {
    // Fonction pour récupérer les matchs depuis l'API
    const fetchMatches = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/tournament/${id}/matches`);
        const data = await response.json();

        if (response.ok) {
          setMatches(data || []); // S'assurer que data.matches est bien un tableau
          
        } else {
          // console.error('Failed to fetch matches:', data.message);
          setMatches([]); // En cas d'erreur, définir matches comme tableau vide
        }
      } catch (error) {
        // console.error('Error fetching matches:', error);
        setMatches([]); // En cas d'erreur, définir matches comme tableau vide
      }
    };

    fetchMatches();
  }, [id]);

  // Filtrer les matchs en fonction du filtre sélectionné
  const filteredMatches = matches.filter(match => match.statusMatch === filter);

  return (
    <div className='matchsContainer'>
      {/* Boutons pour filtrer les matchs */}
      <div className="filterButtons">
        <button
          className={`comingMatchesBtn ${filter === 'à venir' ? 'active' : ''}`}
          onClick={() => setFilter('à venir')}
        >
          À venir
        </button>
        <button
          className={`finishedMatchesBtn ${filter === 'terminé' ? 'active' : ''}`}
          onClick={() => setFilter('terminé')}
        >
          Terminé
        </button>
      </div>

      {/* Afficher les matchs filtrés */}
      {filteredMatches.length > 0 ? (
        filteredMatches.map((match) => (
          <MatchesCard key={match.id} match={match} /> // Passer les données de chaque match en tant que prop au composant MatchesCard
        ))
      ) : (
        <p>Aucun match trouvé.</p> // Message à afficher si aucun match n'est trouvé
      )}
    </div>
  );
}

export default Matchs;
