// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comingMatchesBtn,
.finishedMatchesBtn {
    display: flex;
    padding: 8px 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    color: #0C0B20;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    /* 12px */
    border-radius: 4px;
border: 1px solid #69C3C5;
background: none;
color: #69C3C5;
}

.filterButtons button.active{
    border-radius: 4px;
border: 1px solid #69C3C5;
background: #69C3C5;
color: #0C0B20;
}

.filterButtons {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;

}`, "",{"version":3,"sources":["webpack://./src/components/Overview/Matchs.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,iBAAiB;IACjB,sBAAsB;IACtB,uBAAuB;IACvB,QAAQ;IACR,cAAc;IACd,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,SAAS;IACT,kBAAkB;AACtB,yBAAyB;AACzB,gBAAgB;AAChB,cAAc;AACd;;AAEA;IACI,kBAAkB;AACtB,yBAAyB;AACzB,mBAAmB;AACnB,cAAc;AACd;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;;AAEvB","sourcesContent":[".comingMatchesBtn,\r\n.finishedMatchesBtn {\r\n    display: flex;\r\n    padding: 8px 12px;\r\n    flex-direction: column;\r\n    align-items: flex-start;\r\n    gap: 4px;\r\n    color: #0C0B20;\r\n    text-align: center;\r\n    font-family: Lato;\r\n    font-size: 16px;\r\n    font-style: normal;\r\n    font-weight: 700;\r\n    line-height: 100%;\r\n    /* 12px */\r\n    border-radius: 4px;\r\nborder: 1px solid #69C3C5;\r\nbackground: none;\r\ncolor: #69C3C5;\r\n}\r\n\r\n.filterButtons button.active{\r\n    border-radius: 4px;\r\nborder: 1px solid #69C3C5;\r\nbackground: #69C3C5;\r\ncolor: #0C0B20;\r\n}\r\n\r\n.filterButtons {\r\n    display: flex;\r\n    gap: 12px;\r\n    margin-bottom: 16px;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
