import React from 'react'
import './MenuAdmin.css'
import { useNavigate } from 'react-router-dom';
import { TbSettings , TbPhone } from "react-icons/tb";
import { easeInOut, motion } from "framer-motion"

function MenuAdmin() {

    const navigate = useNavigate();

  const handleMenu = (path) => {
    navigate(path);
  };
  return (
    <motion.div variants={{
      hidden: {opacity:0, bottom: -100},
      visible: {opacity: 1, bottom: 0}
    }} initial="hidden" animate="visible" transition={{ease: easeInOut,duration: 0.5, delay: 1.3}} className='menuAdminContainer'>
        <ul>
        <li onClick={() => handleMenu('/admin')}>
          <TbSettings size={30} color='#0C0B20' />
          Admin
        </li>
        <li onClick={() => handleMenu('/contact')}>
          <TbPhone size={30} color='#0C0B20' />
          Contact
        </li>
      </ul>
    </motion.div>
  )
}

export default MenuAdmin