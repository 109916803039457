import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/Styles/Login.css"; // Fichier CSS pour le style de la page de connexion
import mainLogo from "../assets/Images/logo_open_challenge.svg"; // Logo principal de l'application
import { TbLogin2, TbUser, TbLockOpen, TbChevronLeft } from "react-icons/tb"; // Icônes utilisées dans le formulaire
import PartnersSlider from "../components/UI/PartnersSlider";

function Login() {
  // États pour gérer les valeurs des champs de saisie et les messages
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate(); // Hook pour la navigation
  const apiUrl = process.env.REACT_APP_API_URL; // URL de l'API

  // Effet pour rediriger l'utilisateur vers l'accueil s'il est déjà connecté
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/accueil");
    }
  }, [navigate]);

  // Fonction pour gérer la soumission du formulaire de connexion
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Envoi des données de connexion à l'API
    const response = await fetch(`${apiUrl}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }), // Convertit les données en JSON
    });

    const data = await response.json(); // Récupère la réponse en JSON
    if (response.ok) {
      // Stocke les informations de l'utilisateur dans le localStorage
      localStorage.setItem('userId', data.user.id);
      localStorage.setItem('token', data.token);
      setMessage("Login successful");
      navigate('/accueil'); // Redirige vers la page d'accueil
    } else if (response.status === 403 && !data.isValid) {
      // Redirige vers la page de vérification si le compte est invalide
      navigate(`/verification?email=${email}`);
    } else {
      setMessage("Nom d'utilisateur et/ou mot de passe invalide."); // Affiche un message d'erreur
    }

  };

  // Fonction pour rediriger l'utilisateur vers la page d'inscription
  const handleRegister = () => {
    navigate('/inscription');
  }

  return (
    <div className="loginContainer">
      <div className="loginBanner">
      {/* Affiche le logo principal */}
      <TbChevronLeft className='backBtn' size={40} onClick={()=> {navigate(-1)}} />
      <img className="mainLogo" alt="logo_OC" src={mainLogo} />
      </div>
      {/* Formulaire de connexion */}
      <form className="loginForm" onSubmit={handleSubmit}>
        {message && <p>{message}</p>} {/* Affiche un message si nécessaire */}

        {/* Champ pour l'adresse e-mail */}
        <div className="formGroup">
          <label htmlFor="email"><TbUser size={20} color="#656565" /></label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Adresse e-mail"
          />
        </div>

        {/* Champ pour le mot de passe */}
        <div className="formGroup">
          <label htmlFor="password"><TbLockOpen size={20} color="#656565" /></label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Mot de passe"
          />
        </div>

        {/* Liens pour les options de code et de mot de passe */}
        <div onClick={()=>{ navigate('/resend-code')}} className="loginCode">
          <p>Je n'ai pas reçu de code</p>
          {/* <p>Saisir le code</p> */}
        </div>

        {/* Bouton de connexion */}
        <button className="loginBtn" type="submit">
          Connexion <TbLogin2 size={40} />
        </button>
      </form>

      {/* Lien pour s'inscrire */}
      <div className="noAccount">
        <p>
          Pas de compte ? <span onClick={handleRegister}>Inscris-toi</span>
        </p>
      </div>

      <PartnersSlider/>
    </div>
  );
}

export default Login;
