import React, { useState } from 'react';
import '../assets/Styles/TournamentOverview.css';
import DetailsBanner from '../components/Tournament/DetailsBanner';
import OverviewMenu from '../components/UI/OverviewMenu';
import Classement from '../components/Overview/Classement';
import Poules from '../components/Overview/Poules';
import Matchs from '../components/Overview/Matchs';
import Finales from '../components/Overview/Finales';
import { easeInOut, motion } from "framer-motion"
import PartnersSlider from '../components/UI/PartnersSlider';

function TournamentOverview() {
  // Gestion de l'état pour suivre l'onglet actif
  const [activeTab, setActiveTab] = useState('Classement');

  // Fonction pour changer l'onglet actif lors d'un clic
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Fonction pour rendre le contenu de l'onglet actif
  const renderActiveTab = () => {
    switch (activeTab) {
      case 'Classement':
        return <Classement />;
      case 'Poules':
        return <Poules />;
      case 'Matchs':
        return <Matchs />;
      case 'Finales':
        return <Finales />;
      default:
        return null;
    }
  };

  return (
    <div className='tournamentOverviewContainer'>
      {/* Affichage de la bannière des détails du tournoi */}
      <DetailsBanner />

      <PartnersSlider/>

    <motion.div variants={{
      hidden: {opacity:0, y: 50},
      visible: {opacity: 1, y: 0}
    }} initial="hidden" animate="visible" transition={{ease: easeInOut,duration: 0.3, delay: 0.55}} style={{width: "100%"}}>

    
      {/* Menu pour naviguer entre les onglets */}
      <OverviewMenu activeTab={activeTab} handleTabClick={handleTabClick} />

      {/* Contenu de l'onglet actif */}
      <div className='tabContent'>
        {renderActiveTab()}
      </div>
      </motion.div>
    </div>
  );
}

export default TournamentOverview;
