import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Login from './pages/Login';
import Accueil from './pages/Accueil';
import TournamentDetails from './pages/TournamentDetails';
import MonCompte from './pages/MonCompte';
import TournamentOverview from './pages/TournamentOverview';
import Register from './pages/Register';
import MonEquipe from './pages/MonEquipe';
import AdminDashboard from './pages/AdminDashboard';
import './App.css';
import Contact from './pages/Contact';
import Verification from './pages/Verification';
import AdminTournamentDetails from './components/AdminDashboard/Tournament/AdminTournamentDetails';
import AdminTournamentMatchesDetails from './components/AdminDashboard/Tournament/Matches/AdminTournamentMatchesDetails';
import PageTransition from './components/PageTransition';
import ResendCode from './pages/ResendCode';
import Infos from './pages/Infos';


function App() {
  return (
    <Router>
      <MainApp />
    </Router>
  );
}

function MainApp() {
  const location = useLocation();

  return (
    <div style={{ position: 'relative' }}>
      <AnimatePresence mode="wait" >
          <motion.div
            key={location.pathname}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className='test1'
          >
            <Routes location={location}>
              <Route path="/login" element={<PageTransition><Login /></PageTransition>} />
              <Route path="/resend-code" element={<PageTransition><ResendCode /></PageTransition>} />
              <Route path="/admin" element={<PrivateAdmin component={AdminDashboard} />} />
              <Route path="/admin/tournament/:tournamentId" element={<PrivateAdmin component={AdminTournamentDetails} />} />
              <Route path="/admin/tournament/:tournamentId/matches/:matchId" element={<PrivateAdmin component={AdminTournamentMatchesDetails} />} />
              <Route path="/accueil" element={<PageTransition><Accueil /></PageTransition>} />
              <Route path="/tournament/:id" element={<PageTransition><TournamentDetails /></PageTransition>} />
              <Route path="/tournament/:id/overview" element={<PageTransition><TournamentOverview /></PageTransition>} />
              <Route path="/inscription" element={<PageTransition><Register /></PageTransition>} />
              <Route path="/contact" element={<PageTransition><Contact /></PageTransition>} />
              <Route path="/infos" element={<PageTransition><Infos /></PageTransition>} />
              <Route path="/verification" element={<PageTransition><Verification /></PageTransition>} />
              <Route path="/mon-compte" element={<PrivateRoute component={MonCompte} />} />
              <Route path="/mon-equipe" element={<PrivateRoute component={MonEquipe} />} />
              <Route path="/" element={<Navigate to="/accueil" />} />
            </Routes>
          </motion.div>
      </AnimatePresence>
    </div>
  );
}

function PrivateRoute({ component: Component }) {
  return localStorage.getItem('token') ? (
    <PageTransition><Component /></PageTransition>
  ) : (
    <Navigate to="/login" />
  );
}

function PrivateAdmin({ component: Component }) {
  const [isAdmin, setIsAdmin] = useState(null);

  useEffect(() => {
    const checkAdminStatus = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setIsAdmin(false);
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/check-role`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const data = await response.json();
        if (response.ok && ( data.role === 'admin' || data.role === 'superadmin' )) {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      } catch (error) {
        console.error('Error checking admin status:', error);
        setIsAdmin(false);
      }
    };

    checkAdminStatus();
  }, []);

  if (isAdmin === null) {
    return <div>Loading...</div>;
  }

  return isAdmin ? <PageTransition><Component /></PageTransition> : <Navigate to="/login" />;
}

export default App;
