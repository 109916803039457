import React, { useState } from 'react';
import './TeamEditPopup.css';
import { TbPencil } from "react-icons/tb";
import { IoCloseOutline } from "react-icons/io5";

function TeamEditPopup({ team, onClose, onSave, onRefreshTeams }) {
  const [formData, setFormData] = useState({
    name: team.name,
  });

  const [playersData, setPlayersData] = useState(
    team.players.map((player) => ({
      ...player,
      originalData: { ...player }, // Sauvegarde des infos originales pour annuler
    }))
  );

  const [activePlayerId, setActivePlayerId] = useState(null); // État pour suivre le joueur actif

  // Fonction pour gérer le changement de l'équipe (nom)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Fonction pour gérer le changement des informations d'un joueur
  const handlePlayerChange = (playerId, fieldName, value) => {
    setPlayersData((prevData) =>
      prevData.map((player) =>
        player.id === playerId ? { ...player, [fieldName]: value } : player
      )
    );
  };

  // Fonction pour sauvegarder les modifications de l'équipe
  const handleSaveTeam = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/teams/${team.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to update team');
      }

      onSave(); // Actualiser les équipes après la sauvegarde
      onRefreshTeams(); // Rafraîchir la liste des équipes après modification
      onClose(); // Fermer la popup
    } catch (error) {
      console.error('Error updating team:', error);
    }
  };

  // Fonction pour sauvegarder les modifications d'un joueur
  const handleSavePlayer = async (playerId) => {
    const player = playersData.find((p) => p.id === playerId);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/players/${playerId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(player),
      });

      if (!response.ok) {
        throw new Error('Failed to update player');
      }

      // Met à jour les infos originales après la sauvegarde réussie
      setPlayersData((prevData) =>
        prevData.map((p) =>
          p.id === playerId ? { ...p, originalData: { ...p } } : p
        )
      );
      
      onRefreshTeams(); // Rafraîchir la liste des équipes après modification du joueur
    } catch (error) {
      console.error('Error updating player:', error);
    }
  };

  // Fonction pour annuler les modifications d'un joueur
  const handleCancelPlayer = (playerId) => {
    setPlayersData((prevData) =>
      prevData.map((player) =>
        player.id === playerId ? { ...player.originalData } : player
      )
    );
    setActivePlayerId(null); // Ferme le toggle du joueur
  };

  // Fonction pour gérer l'ouverture/fermeture des toggles des joueurs
  const togglePlayerDetails = (playerId) => {
    if (activePlayerId === playerId) {
      // Si le joueur est déjà actif, on ferme le toggle et on restaure les données originales
      setPlayersData((prevData) =>
        prevData.map((player) =>
          player.id === playerId ? { ...player.originalData } : player
        )
      );
      setActivePlayerId(null); // Fermer le toggle
    } else {
      // Sinon, on active ce joueur et ferme tout autre joueur actif
      setActivePlayerId(playerId);
    }
  };

  return (
    <div className="popupOverlay">
      <div className="popupContent">
        <h3>Modifier l'équipe</h3>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Nom de l'équipe"
        />

        <button className='saveBtn' onClick={handleSaveTeam}>Sauvegarder</button>
        <button className='cancelBtn' onClick={onClose}>Annuler</button>

        <h4>Liste des joueurs</h4>
        <div className="playersList">
          {playersData.map((player) => (
            <div key={player.id} className="playerItem">
              <div className="playerHeader" onClick={() => togglePlayerDetails(player.id)}>
                <p>{player.firstName} {player.lastName} - N°{player.number}</p>
                <button>{activePlayerId === player.id ? (<IoCloseOutline />) : (<TbPencil size={16} />)}</button>
              </div>
              {activePlayerId === player.id && (
                <div className="playerDetails">
                  <label>Prénom</label>
                  <input
                    type="text"
                    value={player.firstName}
                    onChange={(e) => handlePlayerChange(player.id, 'firstName', e.target.value)}
                    placeholder="Prénom"
                  />
                  <label>Nom</label>
                  <input
                    type="text"
                    value={player.lastName}
                    onChange={(e) => handlePlayerChange(player.id, 'lastName', e.target.value)}
                    placeholder="Nom"
                  />
                  <label>Numéro</label>
                  <input
                    type="number"
                    value={player.number}
                    onChange={(e) => handlePlayerChange(player.id, 'number', e.target.value)}
                    placeholder="Numéro"
                  />
                  <label>Âge</label>
                  <input
                    type="number"
                    value={player.age}
                    onChange={(e) => handlePlayerChange(player.id, 'age', e.target.value)}
                    placeholder="Âge"
                  />
                  <label>Adresse</label>
                  <input
                    type="text"
                    value={player.address}
                    onChange={(e) => handlePlayerChange(player.id, 'address', e.target.value)}
                    placeholder="Adresse"
                  />
                  <label>Téléphone</label>
                  <input
                    type="tel"
                    value={player.phoneNumber}
                    onChange={(e) => handlePlayerChange(player.id, 'phoneNumber', e.target.value)}
                    placeholder="Téléphone"
                  />
                  <label>Taille T-shirt</label>
                  <input
                    type="text"
                    value={player.tshirtSize || ''}
                    onChange={(e) => handlePlayerChange(player.id, 'tshirtSize', e.target.value)}
                    placeholder="Taille T-shirt"
                  />
                  <div className='playersEditBtn'>
                    <button className='saveBtn' onClick={() => handleSavePlayer(player.id)}>Sauvegarder</button>
                    <button className='cancelBtn' onClick={() => handleCancelPlayer(player.id)}>Annuler</button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TeamEditPopup;
