// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.monEquipeContainer {
  width: 100%;
  min-height: 100vh;
  background: #0c0b20;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 40px 0px;
}

.monEquipeLogoName {
  display: flex;
  width: 100%;
  padding: 0px 20px;
  align-items: center;
  gap: 24px;
  max-width: 500px;
}

.monEquipeLogoName img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
}

.monEquipeLogoName p {
  color: #C3D32C;
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/assets/Styles/MonEquipe.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,iBAAiB;EACjB,mBAAmB;EACnB,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".monEquipeContainer {\r\n  width: 100%;\r\n  min-height: 100vh;\r\n  background: #0c0b20;\r\n  color: white;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  gap: 10px;\r\n  padding: 40px 0px;\r\n}\r\n\r\n.monEquipeLogoName {\r\n  display: flex;\r\n  width: 100%;\r\n  padding: 0px 20px;\r\n  align-items: center;\r\n  gap: 24px;\r\n  max-width: 500px;\r\n}\r\n\r\n.monEquipeLogoName img {\r\n  width: 100px;\r\n  height: 100px;\r\n  border-radius: 100%;\r\n  object-fit: cover;\r\n  object-position: center;\r\n}\r\n\r\n.monEquipeLogoName p {\r\n  color: #C3D32C;\r\n  text-align: center;\r\n  font-family: Lato;\r\n  font-size: 20px;\r\n  font-style: normal;\r\n  font-weight: 700;\r\n  line-height: normal;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
