// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rewardCardContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80px;
    max-height: 80px;
    min-width: 80px;
    min-height: 80px;
    background: white;
    border-radius: 8px;
    overflow: hidden;
}

.rewardCardContainer img{
    width: 80px;
}`, "",{"version":3,"sources":["webpack://./src/components/Tournament/RewardsCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".rewardCardContainer{\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    max-width: 80px;\r\n    max-height: 80px;\r\n    min-width: 80px;\r\n    min-height: 80px;\r\n    background: white;\r\n    border-radius: 8px;\r\n    overflow: hidden;\r\n}\r\n\r\n.rewardCardContainer img{\r\n    width: 80px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
