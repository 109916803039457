import React, { useEffect, useState } from "react";
import "./ClassementCard.css";

function ClassementCard({ tournamentId }) {
  const [teamStats, setTeamStats] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchTeamStats = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/teams/${tournamentId}/team-stats`);
        if (!response.ok) {
          throw new Error('Failed to fetch team stats');
        }
        const data = await response.json();
        // Sort the teams by points in descending order
        const sortedTeams = data.sort((a, b) => b.points - a.points);
        setTeamStats(sortedTeams);
      } catch (error) {
        console.error('Error fetching team stats:', error);
      }
    };

    fetchTeamStats();
  }, [tournamentId]);

  // Filter and sort teams based on the search term
  const filteredTeams = teamStats
    .filter(team => team.teamName.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <div className="classementCardContainer">
      <input
        type="text"
        placeholder="Rechercher une équipe"
        className="searchInput"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="classementCardSlider">
        <div className="classementCardSliderContent">
          <table className="teamsTable">
            <thead>
              <tr>
                <th className="teamTh">ÉQUIPE</th>
                <th className="points">PTS</th>
                <th className="win">G</th>
                <th className="defeat">D</th>
                <th className="goalFor">BP</th>
                <th className="goalConceded">BC</th>
              </tr>
            </thead>
            <tbody>
              {filteredTeams.map((team, index) => (
                <tr key={index}>
                  <td className="teamInfo">
                    <span className="teamPosition">{index + 1}</span> {/* Position de l'équipe */}
                    <img
                      src={process.env.REACT_APP_API_URL + "/" + team.teamLogo || 'https://www.strasys.uk/wp-content/uploads/2022/02/Depositphotos_484354208_S.jpg'}
                      alt={team.teamName}
                      className="teamLogo"
                    />
                    <span>{team.teamName}</span>
                  </td>
                  <td className="points">{team.points !== undefined ? team.points : '-'}</td>
                  <td>{team.matchesWon !== undefined ? team.matchesWon : '-'}</td>
                  <td>{team.matchesLost !== undefined ? team.matchesLost : '-'}</td>
                  <td>{team.goalsScored !== undefined ? team.goalsScored : '-'}</td>
                  <td>{team.goalsConceded!== undefined ? team.goalsConceded : '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ClassementCard;
