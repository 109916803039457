import React, { useEffect, useState } from "react";
import "./PoulesCard.css";

function PoulesCard({ groupName, teams, tournamentId }) {
  const [teamStats, setTeamStats] = useState([]);
  const [teamLogos, setTeamLogos] = useState({});

  useEffect(() => {
    const fetchTeamStats = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/teams/${tournamentId}/team-stats`);
        if (!response.ok) {
          throw new Error('Failed to fetch team stats');
        }
        const data = await response.json();
        setTeamStats(data);
      } catch (error) {
        console.error('Error fetching team stats:', error);
      }
    };

    fetchTeamStats();
  }, [tournamentId]);

  useEffect(() => {
    const fetchTeamLogos = async () => {
      const logos = {};
      for (const team of teams) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/teams/${team.id}`);
          if (response.ok) {
            const data = await response.json();
            logos[team.id] = data.logo ? `${process.env.REACT_APP_API_URL}/${data.logo}` : 'https://www.strasys.uk/wp-content/uploads/2022/02/Depositphotos_484354208_S.jpg';
          } else {
            logos[team.id] = 'https://www.strasys.uk/wp-content/uploads/2022/02/Depositphotos_484354208_S.jpg';
          }
        } catch (error) {
          console.error(`Error fetching logo for team ${team.id}:`, error);
          logos[team.id] = 'https://www.strasys.uk/wp-content/uploads/2022/02/Depositphotos_484354208_S.jpg'; // Image de secours en cas d'erreur
        }
      }
      setTeamLogos(logos);
    };

    fetchTeamLogos();
  }, [teams]);

  const getTeamStatsById = (teamId) => {
    return teamStats.find((team) => team.teamId === teamId) || {};
  };

  const sortedTeams = teams.map((team) => ({
    ...team,
    stats: getTeamStatsById(team.id),
    logo: teamLogos[team.id] || 'https://www.strasys.uk/wp-content/uploads/2022/02/Depositphotos_484354208_S.jpg',
  })).sort((a, b) => b.stats.points - a.stats.points);

  return (
    <div className="poulesCardContainer">
      <p>{groupName}</p>
      <div className="poulesCardSlider">
        <div className="poulesCardSliderContent">
          <table className="teamsTable">
            <thead>
              <tr>
                <th className="teamTh">ÉQUIPE</th>
                <th className="points">PTS</th>
                <th className="win">G</th>
                <th className="defeat">D</th>
                <th className="goalFor">BP</th>
                <th className="goalConceded">BC</th>
              </tr>
            </thead>
            <tbody>
              {sortedTeams.map((team, index) => (
                <tr key={index}>
                  <td className="teamInfo">
                    <img src={team.logo} alt={team.name} className="teamLogo" />
                    <span>{team.name}</span>
                  </td>
                  <td className="points">{team.stats.points !== undefined ? team.stats.points : '-'}</td>
                  <td>{team.stats.matchesWon !== undefined ? team.stats.matchesWon : '-'}</td>
                  <td>{team.stats.matchesLost !== undefined ? team.stats.matchesLost : '-'}</td>
                  <td>{team.stats.goalsScored !== undefined ? team.stats.goalsScored : '-'}</td>
                  <td>{team.stats.goalsConceded !== undefined ? team.stats.goalsConceded : '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PoulesCard;
