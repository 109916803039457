import React, { useState } from "react";
import "./TournamentActionsPopup.css";

function TournamentActionsPopup({ tournament, onClose, refreshMatches }) {
  const [confirmAction, setConfirmAction] = useState(null);

  const handleGeneratePools = async () => {
    setConfirmAction({
      message: "Êtes-vous sûr de vouloir générer les poules ?",
      action: async () => {
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const response = await fetch(
            `${apiUrl}/tournament/${tournament.id}/generate-poules`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: JSON.stringify({
                teamsPerPoule: 4,
              }),
            }
          );

          if (!response.ok) {
            throw new Error("Failed to generate pools");
          }

          const data = await response.json();
          console.log("Poules générées avec succès:", data.poules);
          refreshMatches();
          onClose();
        } catch (error) {
          console.error("Erreur lors de la génération des poules:", error);
        }
      },
    });
  };

  const handleDeletePools = async () => {
    setConfirmAction({
      message: "Êtes-vous sûr de vouloir supprimer toutes les poules ?",
      action: async () => {
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const response = await fetch(
            `${apiUrl}/tournament/${tournament.id}/delete-poules`,
            {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (!response.ok) {
            throw new Error("Failed to delete pools");
          }

          const data = await response.json();
          console.log(
            "Toutes les poules ont été supprimées avec succès:",
            data.message
          );
          refreshMatches();
          onClose();
        } catch (error) {
          console.error("Erreur lors de la suppression des poules:", error);
        }
      },
    });
  };

  const handleGenerateMatches = async () => {
    setConfirmAction({
      message: "Êtes-vous sûr de vouloir générer les matchs ?",
      action: async () => {
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const response = await fetch(
            `${apiUrl}/tournament/${tournament.id}/generate-matches`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (!response.ok) {
            throw new Error("Failed to generate matches");
          }

          const data = await response.json();
          console.log("Les matchs ont été générés avec succès:", data.message);
          refreshMatches();
          onClose();
        } catch (error) {
          console.error("Erreur lors de la génération des matchs:", error);
        }
      },
    });
  };

  const handleDeleteMatches = async () => {
    setConfirmAction({
      message: "Êtes-vous sûr de vouloir supprimer tous les matchs ?",
      action: async () => {
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const response = await fetch(
            `${apiUrl}/tournament/${tournament.id}/delete-matches`,
            {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (!response.ok) {
            throw new Error("Failed to delete matches");
          }

          const data = await response.json();
          console.log(
            "Tous les matchs ont été supprimés avec succès:",
            data.message
          );
          refreshMatches();
          onClose();
        } catch (error) {
          console.error("Erreur lors de la suppression des matchs:", error);
        }
      },
    });
  };

  const handleNextPhase = async () => {
    setConfirmAction({
      message: "Êtes-vous sûr de vouloir passer à la phase suivante ?",
      action: async () => {
        try {
          const apiUrl = process.env.REACT_APP_API_URL;
          const response = await fetch(
            `${apiUrl}/tournament/${tournament.id}/check-pool`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (!response.ok) {
            throw new Error("Failed to proceed to the next phase");
          }

          const data = await response.json();
          console.log("Phase suivante atteinte avec succès:", data.message);
          refreshMatches();
          onClose();
        } catch (error) {
          console.error("Erreur lors du passage à la phase suivante:", error);
        }
      },
    });
  };

  const executeAction = () => {
    if (confirmAction && confirmAction.action) {
      confirmAction.action();
      setConfirmAction(null);
    }
  };

  const cancelAction = () => {
    setConfirmAction(null);
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <ul>
          <div>
            <p>Poules</p>
            <li>
              <button onClick={handleGeneratePools}>Générer les poules</button>
            </li>
            <li>
              <button onClick={handleDeletePools}>
                Supprimer toutes les poules
              </button>
            </li>
          </div>
          <div>
            <p>Matches</p>
            <li>
              <button onClick={handleGenerateMatches}>
                Générer les matchs
              </button>
            </li>
            <li>
              <button onClick={handleDeleteMatches}>
                Supprimer tous les matchs
              </button>
            </li>
          </div>
          <div>
            <p>Phase</p>
            <li>
              <button onClick={handleNextPhase}>
                Passer à la phase suivante
              </button>
            </li>
          </div>
        </ul>
        <button onClick={onClose} className="close-button">
          Fermer
        </button>

        {confirmAction && (
          <div className="confirmation-popup">
            <div className="confirmation-popup-content">
              <p>{confirmAction.message}</p>
              <button className="confirmBtn" onClick={executeAction}>
                Oui
              </button>
              <button className="declineBtn" onClick={cancelAction}>
                Non
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TournamentActionsPopup;
