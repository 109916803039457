import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TbUser, TbPhone, TbMail, TbLock, TbUsersGroup, TbArrowBarRight } from "react-icons/tb";
import '../assets/Styles/MonCompte.css';
import MonCompteBanner from '../components/MonCompte/MonCompteBanner';

function MonCompte() {
  // Déclaration des états pour stocker les informations utilisateur et l'image
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    clubName: '',
    phone: '',
    email: '',
    password: '',
    termsAccepted: false,
    file: null, // Fichier de l'image
  });

  const navigate = useNavigate();

  // Récupération des données utilisateur lors du chargement du composant
  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const userData = await response.json();
        setFormData({
          firstName: userData.firstName || '',
          lastName: userData.lastName || '',
          clubName: userData.clubName || '',
          phone: userData.phone || '',
          email: userData.email || '',
          password: '', // Ne pas pré-remplir le mot de passe pour des raisons de sécurité
          file: userData.logo || 'uploads/defaultTeamImage.jpg', // Charger le logo existant ou une image par défaut
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  // Gestion des changements dans les champs de formulaire
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  // Gestion du changement de fichier pour l'image
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        file: file, // Stocker le fichier dans formData
      });

      // Optionnel : prévisualisation de l'image localement
      const reader = new FileReader();
      reader.onloadend = () => {
        const imagePreview = reader.result;
        setFormData(prevFormData => ({
          ...prevFormData,
          filePreview: imagePreview, // Stocker la prévisualisation de l'image
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  // Gestion de la déconnexion de l'utilisateur
  const handleLogout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('token');
    navigate('/accueil');
  };

  // Gestion du clic sur l'image pour ouvrir la fenêtre de sélection de fichier
  const handleImageClick = (e) => {
    e.stopPropagation(); // Empêcher la propagation de l'événement
    document.getElementById('fileInput').click(); // Déclencher le clic sur l'input de fichier
  };

  // Soumission du formulaire pour mettre à jour les informations utilisateur
  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem('token');

    // Préparation des données à envoyer
    const dataToSend = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      clubName: formData.clubName,
      phone: formData.phone,
      email: formData.email,
      password: formData.password, // Ce champ sera vide si l'utilisateur ne veut pas le modifier
    };

    const formDataObj = new FormData();
    for (const key in dataToSend) {
      formDataObj.append(key, dataToSend[key]);
    }

    if (formData.file) {
      formDataObj.append('file', formData.file); // Ajouter le fichier à l'objet FormData
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formDataObj, // Envoyer l'objet FormData comme corps de la requête
      });

      if (!response.ok) {
        throw new Error('Failed to update user data');
      }

      const updatedUser = await response.json();
      console.log('User data updated:', updatedUser);
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  // Gestion de la suppression du compte utilisateur
  const handleDeleteAccount = () => {
    console.log('Account deletion requested');
  };

  return (
    <div className="monCompteContainer">
      <MonCompteBanner />
      <form className="monCompteForm" onSubmit={handleSubmit}>
        <div className="fileUploadContainer">
          <div className="fileCircle" onClick={handleImageClick}>
            <img
              src={formData.filePreview || `${process.env.REACT_APP_API_URL}/${formData.file}`}
              alt="Team Blason"
              className="uploadedImage"
              onClick={handleImageClick}
            />
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              style={{ display: 'none' }} // Cacher l'input de fichier
              onChange={handleFileChange}
            />
          </div>
        </div>
        <div className="monCompteHeader">
          <div className="monCompteLogout" onClick={handleLogout}>
            <p>Déconnexion</p>
            <TbArrowBarRight className="logoutButton" size={24} color='#ff5353' />
          </div>
        </div>
        <div className="monCompteUserInfo">
          <p>{formData.firstName} {formData.lastName}</p>
          <p>Équipe : {formData.clubName}</p>
        </div>

        <div className="inputGroup">
          <div className="inputWithIcon">
            <TbUser size={24} />
            <input
              type="text"
              name="firstName"
              placeholder="Nom"
              value={formData.firstName}
              onChange={handleChange}
            />
          </div>
          <div className="inputWithIcon">
            <TbUser size={24} />
            <input
              type="text"
              name="lastName"
              placeholder="Prénom"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="inputWithIcon">
          <TbUsersGroup size={24} />
          <input
            type="text"
            name="clubName"
            placeholder="Nom du club"
            value={formData.clubName}
            onChange={handleChange}
            maxLength={10}
          />
        </div>

        <div className="inputWithIcon">
          <TbPhone size={24} />
          <input
            type="tel"
            name="phone"
            placeholder="Téléphone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>

        <div className="inputWithIcon">
          <TbMail size={24} />
          <input
            type="email"
            name="email"
            placeholder="Adresse e-mail"
            value={formData.email}
            onChange={handleChange}
          />
        </div>

        <div className="inputWithIcon">
          <TbLock size={24} />
          <input
            type="password"
            name="password"
            placeholder="Mot de passe"
            value={formData.password}
            onChange={handleChange}
          />
        </div>

        <button className="saveButton" type="submit">ENREGISTRER</button>
      </form>

      <button className="deleteButton" onClick={handleDeleteAccount}>SUPPRIMER LE COMPTE</button>
    </div>
  );
}

export default MonCompte;
