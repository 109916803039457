import React from "react";
import "../Accueil/TournamentCard.css";
import mainLogo from "../../assets/Images/logo_open_challenge.svg";
import { TbMapPin, TbSoccerField, TbUsersGroup, TbGiftCard } from "react-icons/tb";

function TournamentCard({ tournament, onClick }) {
  const tournamentDate = new Date(tournament.date);

  return (
    <div className="tournamentContainer" onClick={() => onClick(tournament.id)}>
      {/* <p>{tournamentDate.toLocaleDateString('fr-FR', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' })}</p> */}
      <p>Tournoi Vétéran</p>
      <div className="tournamentCardWraper">
        <img src={mainLogo} alt="logo tournoi" />
        <div className="tournamentInfos">
          <h1>{tournamentDate.getFullYear()}</h1>
          {/* <h1>Tournoi Vétéran</h1> */}
          <h1>{tournament.name}</h1>
        </div>
        <div className="tournamentInfosPlus">
          <ul>
            <li><TbMapPin size={30}/> {tournament.location}</li>
            <li><TbSoccerField size={30}/> Matches</li>
            <li><TbUsersGroup size={30}/> {tournament.matchType}</li>
            <li><TbGiftCard size={30}/>Lots</li>
          </ul>
          <div className="placesRestantes">
            {tournament.remaining_places} places restantes
          </div>
        </div>
      </div>
    </div>
  );
}

export default TournamentCard;
