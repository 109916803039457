import React from 'react';
import './Poules.css';
import PoulesCard from './Poules/PoulesCard';
import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react';

function Poules() {
  const { id } = useParams();
  const [groupsTeams, setGroupsTeams] = useState([]);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const fetchGroupsTeams = async () => {
      try {
        const response = await fetch(`${apiUrl}/tournament/${id}/groups`);
        const data = await response.json();
        setGroupsTeams(data);
      } catch (error) {
        console.error('Error fetching groups and teams:', error);
      }
    };

    fetchGroupsTeams();
  }, [id]);

  return (
    <div className='poulesContainer'>
      {groupsTeams.map(group => (
        <PoulesCard key={group.id} groupName={group.name} teams={group.teams} tournamentId={id} />
      ))}
    </div>
  );
}

export default Poules;
