import React from 'react';
import './OverviewMenu.css';


function OverviewMenu({ activeTab, handleTabClick }) {
  return (
    <div className='overviewMenuContainer'>
      <div className='overviewMenuTabs'>
        {['Classement', 'Poules', 'Matchs', 'Finales'].map(tab => (
          <div
            key={tab}
            className={`overviewMenuItem ${activeTab === tab ? 'active' : ''}`}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </div>
        ))}
      </div>
    </div>
  );
}

export default OverviewMenu;
