// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registerContainer{
    width: 100%;
  min-height: 100vh;
  background: #0c0b20;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 40px 0px;
}

.StripeElement{
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/assets/Styles/Register.css"],"names":[],"mappings":"AAAA;IACI,WAAW;EACb,iBAAiB;EACjB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".registerContainer{\r\n    width: 100%;\r\n  min-height: 100vh;\r\n  background: #0c0b20;\r\n  color: white;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  gap: 10px;\r\n  padding: 40px 0px;\r\n}\r\n\r\n.StripeElement{\r\n  color: white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
