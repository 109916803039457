import React, { useState, useEffect } from "react";
import './TournamentForm.css'
import { TbTrash, TbPlus, TbX } from "react-icons/tb";


function TournamentForm({
  onSubmit,
  onEdit,
  tournamentData,
  clearSelectedTournament,
}) {
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [showReward, setShowReward] = useState(false);

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [matchType, setMatchType] = useState("");
  const [remainingPlaces, setRemainingPlaces] = useState("");
  const [description, setDescription] = useState("");
  const [additionalInfoId, setAdditionalInfoId] = useState("");

  // Nouveaux champs pour les informations additionnelles
  const [teamCount, setTeamCount] = useState("");
  const [groupCount, setGroupCount] = useState("");
  const [qualificationType, setQualificationType] = useState("");
  const [playtime, setPlaytime] = useState("");
  const [inscriptionType, setInscriptionType] = useState("");
  const [victoryPoints, setVictoryPoints] = useState("");
  const [rankingMatch, setRankingMatch] = useState("");
  const [smallFinal, setSmallFinal] = useState("");
  const [fieldCount, setFieldCount] = useState("");
  const [fieldType, setFieldType] = useState("");
  const [breaktime, setBreaktime] = useState("");
  const [replacementCount, setReplacementCount] = useState("");
  const [gender, setGender] = useState("");

  const [rewards, setRewards] = useState([{ name: "", rewardImg: null, imagePreview: null }]); // État pour les récompenses

  useEffect(() => {
    if (tournamentData) {
      console.log(tournamentData);
      setId(tournamentData.id);
      setName(tournamentData.name);
      setDate(formatDateForInput(tournamentData.date));
      setLocation(tournamentData.location);
      setMatchType(tournamentData.matchType);
      setRemainingPlaces(tournamentData.remaining_places || "");
      setDescription(tournamentData.description);

      setAdditionalInfoId(tournamentData.additionalInfoId || "");

      // Remplir les champs des informations additionnelles si disponibles
      const additionalInfo = tournamentData.additionalInfo || {};
      console.log(additionalInfo);
      setTeamCount(additionalInfo.teamCount || "");
      setGroupCount(additionalInfo.groupCount || "");
      setQualificationType(additionalInfo.qualificationType || "");
      setPlaytime(additionalInfo.playtime || "");
      setInscriptionType(additionalInfo.inscriptionType || "");
      setVictoryPoints(additionalInfo.victoryPoints || "");
      setRankingMatch(additionalInfo.rankingMatch || "");
      setSmallFinal(additionalInfo.smallFinal || "");
      setFieldCount(additionalInfo.fieldCount || "");
      setFieldType(additionalInfo.fieldType || "");
      setBreaktime(additionalInfo.breaktime || "");
      setReplacementCount(additionalInfo.replacementCount || "");
      setGender(additionalInfo.gender || "");

      setRewards(tournamentData.rewards || [{ name: "", rewardImg: null, imagePreview: null }]);
    } else {
      // Réinitialiser les champs si aucun tournoi n'est sélectionné
      setName("");
      setDate("");
      setLocation("");
      setMatchType("");
      setRemainingPlaces("");
      setDescription("");
      setAdditionalInfoId("");

      // Réinitialiser les champs des informations additionnelles
      setTeamCount("");
      setGroupCount("");
      setQualificationType("");
      setPlaytime("");
      setInscriptionType("");
      setVictoryPoints("");
      setRankingMatch("");
      setSmallFinal("");
      setFieldCount("");
      setFieldType("");
      setBreaktime("");
      setReplacementCount("");
      setGender("");
      const formattedRewards = (tournamentData.rewards || []).map(reward => {
        let imagePreview = null;
        if (reward.rewardImg && reward.rewardImg.data) {
          // Convertir le buffer en base64
          const buffer = reward.rewardImg.data;
          const base64String = Buffer.from(buffer).toString('base64');
          imagePreview = `data:image/png;base64,${base64String}`;
        }
        return {
          ...reward,
          imagePreview,
        };
      });
      setRewards(formattedRewards );
    }
  }, [tournamentData]);

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const tournament = {
      name,
      date,
      location,
      matchType,
      remaining_places: remainingPlaces,
      description,
      additionalInfoId,
      additionalInfo: {
        teamCount,
        groupCount,
        qualificationType,
        playtime,
        inscriptionType,
        victoryPoints,
        rankingMatch,
        smallFinal,
        fieldCount,
        fieldType,
        breaktime,
        replacementCount,
        gender,
      },
      rewards, // Assurez-vous que 'rewardImg' contient le Base64 encodé
    };
  
    onSubmit(tournament); // Envoyer les données au backend
  };
  

  const handleEdit = (e) => {
    e.preventDefault();
  
    // Create the tournament object
    const tournament = {
      id,
      name,
      date,
      location,
      matchType,
      remaining_places: remainingPlaces,
      description,
      additionalInfoId,
      additionalInfo: {
        teamCount,
        groupCount,
        qualificationType,
        playtime,
        inscriptionType,
        victoryPoints,
        rankingMatch,
        smallFinal,
        fieldCount,
        fieldType,
        breaktime,
        replacementCount,
        gender,
      },
      rewards,
    };
  
    // Call onEdit with the updated tournament object
    onEdit(tournament);
  };
  

  const handleCancel = () => {
    clearSelectedTournament(); // Réinitialise le tournoi sélectionné dans AdminDashboard
  };

  const formatDateForInput = (dateString) => {
    const date = new Date(dateString);
    const utcPlusFourDate = new Date(date.getTime() + 4 * 60 * 60 * 1000);
    return utcPlusFourDate.toISOString().split("T")[0];
  };

  // Fonctions pour gérer les récompenses
  const handleRewardChange = (index, event) => {
    const newRewards = [...rewards];
    newRewards[index].name = event.target.value;
    setRewards(newRewards);
  };

  const handleRewardImageChange = (index, e) => {
    const file = e.target.files[0];
    const newRewards = [...rewards];
  
    // Utilisez FileReader pour convertir le fichier en Base64
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      newRewards[index].rewardImg = base64String; // Stockez le fichier encodé en Base64
      newRewards[index].imagePreview = URL.createObjectURL(file); // Aperçu de l'image
      setRewards(newRewards);
    };
  
    if (file) {
      reader.readAsDataURL(file); // Lire le fichier en Base64
    }
  };
  

  const handleDeleteImage = (index) => {
    const newRewards = [...rewards];
    newRewards[index].rewardImg = null;
    newRewards[index].imagePreview = null;
    setRewards(newRewards);
  };

  const addReward = () => {
    setRewards([...rewards, { name: "", rewardImg: null, imagePreview: null }]);
  };

  const removeReward = (index) => {
    const newRewards = rewards.filter((_, i) => i !== index);
    setRewards(newRewards);
  };

  return (
    <form
      className="tournament-form"
      onSubmit={tournamentData ? handleEdit : handleSubmit}
    >
      <h2>{tournamentData ? "Modifier le tournoi" : "Ajouter un tournoi"}</h2>
      <p>Saisissez les informations utiles à l'organisation du tournoi.</p>
      <div className="form-group">
        <input
          type="text"
          placeholder="Nom"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Lieu"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <input
          type="date"
          placeholder="Date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Type de match"
          value={matchType}
          onChange={(e) => setMatchType(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <input
          type="number"
          placeholder="Places restantes"
          value={remainingPlaces}
          onChange={(e) => setRemainingPlaces(e.target.value)}
        />
      </div>
      <div className="form-group">
        <textarea
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
      </div>

      <div className="additional-info-section">
        <button
          type="button"
          className="toggle-button"
          onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}
        >
          {showAdditionalInfo
            ? "Masquer les informations additionnelles"
            : "Afficher les informations additionnelles"}
        </button>

        {showAdditionalInfo && (
          <div className="additional-info-content tournamentAdditional">
            <div className="form-group">
              <input
                type="number"
                placeholder="Nombre d'équipes"
                value={teamCount}
                onChange={(e) => setTeamCount(e.target.value)}
              />
              <input
                type="number"
                placeholder="Nombre de poules"
                value={groupCount}
                onChange={(e) => setGroupCount(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Type de qualification"
                value={qualificationType}
                onChange={(e) => setQualificationType(e.target.value)}
              />
              <input
                type="number"
                placeholder="Temps de jeu (min)"
                value={playtime}
                onChange={(e) => setPlaytime(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Type d'inscription"
                value={inscriptionType}
                onChange={(e) => setInscriptionType(e.target.value)}
              />
              <input
                type="number"
                placeholder="Points par victoire"
                value={victoryPoints}
                onChange={(e) => setVictoryPoints(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Match de classement"
                value={rankingMatch}
                onChange={(e) => setRankingMatch(e.target.value)}
              />
              <input
                type="text"
                placeholder="Petite finale"
                value={smallFinal}
                onChange={(e) => setSmallFinal(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                type="number"
                placeholder="Nombre de terrains"
                value={fieldCount}
                onChange={(e) => setFieldCount(e.target.value)}
              />
              <input
                type="text"
                placeholder="Type de terrain"
                value={fieldType}
                onChange={(e) => setFieldType(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                type="number"
                placeholder="Temps de pause (min)"
                value={breaktime}
                onChange={(e) => setBreaktime(e.target.value)}
              />
              <input
                type="number"
                placeholder="Nombre de remplacements"
                value={replacementCount}
                onChange={(e) => setReplacementCount(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Genre"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              />
            </div>
          </div>
        )}
      </div>

      {/* Champs pour les rewards */}
      <div className="additional-info-section">
        <button
          type="button"
          className="toggle-button"
          onClick={() => setShowReward(!showReward)}
        >
          {showReward
            ? "Masquer les récompenses"
            : "Afficher les récompenses"}
        </button>

        {showReward && (
          <div className="additional-info-content">
            <div className="rewards-section">
              <h3>Récompenses</h3>
              {rewards.map((reward, index) => (
                <div key={index} className="form-group reward-item">
                  <input
                    type="text"
                    placeholder={`Récompense ${index + 1}`}
                    value={reward.name}
                    onChange={(e) => handleRewardChange(index, e)}
                    required
                  />
                  {reward.imagePreview ? (
                    <div className="reward-image-container">
                      <img src={reward.imagePreview} alt={`Reward ${index + 1}`} className="reward-image" />
                      <button type="button" className="delete-image-btn" onClick={() => handleDeleteImage(index)}>
                        <TbX size={8} />
                      </button>
                    </div>
                  ) : (
                    <label htmlFor={`rewardImage-${index}`} className="add-image-icon">
                      <TbPlus size={20} />
                      <input
                        type="file"
                        id={`rewardImage-${index}`}
                        accept="image/*"
                        onChange={(e) => handleRewardImageChange(index, e)}
                        style={{ display: "none" }}
                      />
                    </label>
                  )}
                  {rewards.length > 1 && (
                    <button type="button" onClick={() => removeReward(index)}>
                      <TbTrash size={20} />
                    </button>
                  )}
                </div>
              ))}
              <button type="button" className="add-reward-button" onClick={addReward}>
                Ajouter une récompense
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="form-group">
        <button type="submit" className="submit-button">
          {tournamentData ? "Mettre à jour" : "Enregistrer"}
        </button>

        <button
          type="button"
          onClick={handleCancel}
          className="cancel-button"
        >
          Annuler
        </button>
      </div>
    </form>
  );
}

export default TournamentForm;
