import React from 'react'
import './Classement.css'
import ClassementCard from './Classement/ClassementCard'
import { useParams } from 'react-router-dom';


function Classement() {
  const { id } = useParams();
  return (
    <div className='classementContainer'>
      <ClassementCard tournamentId={id}/>
    </div>
  )
}

export default Classement