// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tournamentOverviewContainer {
    width: 100%;
    min-height: 100vh;
    background: #0c0b20;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 40px 0px;
}

.tournamentOverviewContainer > div{
    width: 100%;
    max-width: 500px;
}

.tabContent{
    width: 100%;
    padding: 0px 20px;
}`, "",{"version":3,"sources":["webpack://./src/assets/Styles/TournamentOverview.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB","sourcesContent":[".tournamentOverviewContainer {\r\n    width: 100%;\r\n    min-height: 100vh;\r\n    background: #0c0b20;\r\n    color: white;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    gap: 10px;\r\n    padding: 40px 0px;\r\n}\r\n\r\n.tournamentOverviewContainer > div{\r\n    width: 100%;\r\n    max-width: 500px;\r\n}\r\n\r\n.tabContent{\r\n    width: 100%;\r\n    padding: 0px 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
