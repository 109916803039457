import React from 'react'
import './ErrorPage.css'
import mainLogo from '../../assets/Images/logo_open_challenge.svg'
import { useNavigate } from 'react-router-dom'

function ErrorPage() {

    const navigate = useNavigate()

    const handleBackHome = () => {
        navigate('/')
    }

  return (
    <div className='errorPageContainer'>
        <img src={mainLogo} alt="" />
        <p>Une erreur s'est produite.</p>
        <button onClick={()=>handleBackHome()}>Retour à l'accueil</button>
    </div>
  )
}

export default ErrorPage