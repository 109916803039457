import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/Styles/Accueil.css";
import MenuBottom from "../components/UI/MenuBottom";
import MenuAdmin from "../components/UI/MenuAdmin";
import TournamentCard from "../components/Accueil/TournamentCard";
import mainLogo from "../assets/Images/logo_open_challenge.svg";
import { TbUser } from "react-icons/tb";
import { easeInOut, motion } from "framer-motion";
import PartnersSlider from "../components/UI/PartnersSlider";

function Accueil() {
  const [tournaments, setTournaments] = useState(null);
  const [role, setRole] = useState("user");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    console.log("URL de l'API:", apiUrl);

    console.log("Début du chargement des tournois");
    fetch(`${apiUrl}/tournaments`)
      .then((response) => {
        console.log("Réponse reçue:", response);
        return response.json();
      })
      .then((data) => {
        console.log("Données reçues (type):", typeof data);
        console.log("Données reçues (contenu):", JSON.stringify(data, null, 2));
        if (Array.isArray(data)) {
          setTournaments(data);
        } else if (typeof data === "object" && data !== null) {
          const tournamentArray = Object.values(data).find(Array.isArray);
          if (tournamentArray) {
            setTournaments(tournamentArray);
          } else {
            const tournamentArray = Object.values(data);
            if (
              tournamentArray.length > 0 &&
              typeof tournamentArray[0] === "object"
            ) {
              setTournaments(tournamentArray);
            } else {
              console.error("Structure de données inattendue:", data);
              setTournaments([]);
            }
          }
        } else {
          console.error(
            "Les données reçues ne sont ni un tableau ni un objet:",
            data
          );
          setTournaments([]);
        }
      })
      .catch((error) => {
        console.error("Erreur lors du chargement des tournois:", error);
        setTournaments([]);
      });

    if (token) {
      fetch(`${apiUrl}/check-role`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.role) {
            setRole(data.role);
          } else {
            console.error("Failed to fetch user role:", data.message);
          }
        })
        .catch((error) => console.error("Error fetching user role:", error));
    }
  }, [token]);

  const handleCardClick = (id) => {
    navigate(`/tournament/${id}`);
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const renderTournaments = () => {
    console.log("Rendu des tournois. État actuel:", tournaments);
    if (tournaments === null) {
      return <p className="loading-message">Chargement des tournois...</p>;
    }
    if (!Array.isArray(tournaments)) {
      console.error("tournaments n'est pas un tableau:", tournaments);
      return (
        <p className="error-message">Erreur lors du chargement des tournois</p>
      );
    }
    if (tournaments.length === 0) {
      return <p className="no-tournaments-message">Aucun tournoi en cours</p>;
    }
    return tournaments.map((tournament) => (
      <TournamentCard
        key={tournament.id}
        tournament={tournament}
        onClick={handleCardClick}
      />
    ));
  };

  return (
    <div className="accueilContainer">
      <motion.img
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate="visible"
        transition={{ ease: easeInOut, duration: 0.25, delay: 0.25 }}
        className="homeLogo"
        alt="logo_OC"
        src={mainLogo}
      />

      <div className="homeLoginContainer">
        {!token && (
          <button onClick={handleLogin} className="homeLoginButton">
            <TbUser size={24} />
          </button>
        )}
      </div>
      
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate="visible"
        transition={{ ease: easeInOut, duration: 0.5, delay: 1 }}
        className="howItWorks"
      >
        
        <h2>Tu veux participer ?</h2>

        <button class="subscribeBtn"
          onClick={() => {
            navigate("/inscription");
          }}
        >
          Inscrire mon équipe
        </button>
      </motion.div>

        <h2 class="headWithSpace">Tournoi Vétéran</h2>
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate="visible"
        transition={{ ease: easeInOut, duration: 0.5, delay: 0.5 }}
        className="tournamentsWraper"
      >
        {renderTournaments()}
      </motion.div>
      <PartnersSlider />
      <div>
        <button onClick={() => {
            navigate("/infos");
          }} className="infosBtn">Informations complémentaires</button>
          <button className="contactBtn"
          onClick={() => {
            navigate("/contact");
          }}
        >
          Nous contacter
        </button>
      </div>
      

      {token &&
        (role === "admin" || role === "superadmin" ? (
          <MenuAdmin />
        ) : (
          <MenuBottom />
        ))}
    </div>
  );
}

export default Accueil;
