// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorPageContainer{
    width: 100%;
    min-height: 100vh;
    background: #0C0B20;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
}

.errorPageContainer p{
    color: white;
    font-size: 18px;

}

.errorPageContainer img{
    width: 120px;
}


.errorPageContainer button{
    color: white;
    padding: 16px 24px;
    background: none;
    border: 1px solid #C3D32C;
    border-radius: 16px;
    width: 250px;
    font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/UI/ErrorPage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,eAAe;;AAEnB;;AAEA;IACI,YAAY;AAChB;;;AAGA;IACI,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".errorPageContainer{\r\n    width: 100%;\r\n    min-height: 100vh;\r\n    background: #0C0B20;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    flex-direction: column;\r\n    gap: 16px;\r\n}\r\n\r\n.errorPageContainer p{\r\n    color: white;\r\n    font-size: 18px;\r\n\r\n}\r\n\r\n.errorPageContainer img{\r\n    width: 120px;\r\n}\r\n\r\n\r\n.errorPageContainer button{\r\n    color: white;\r\n    padding: 16px 24px;\r\n    background: none;\r\n    border: 1px solid #C3D32C;\r\n    border-radius: 16px;\r\n    width: 250px;\r\n    font-size: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
