import React from 'react';
import { TbUsersGroup, TbUserCircle, TbPhone } from "react-icons/tb";
import "./MenuBottom.css";
import { useNavigate } from 'react-router-dom';
import { easeInOut, motion } from "framer-motion"

function MenuBottom() {
  const navigate = useNavigate();

  const handleMenu = (path) => {
    navigate(path);
  };

  return (
    <motion.div variants={{
      hidden: {opacity:0, bottom: -100},
      visible: {opacity: 1, bottom: 0}
    }} initial="hidden" animate="visible" transition={{ease: easeInOut,duration: 0.5, delay: 1.3}} className="menuBottom">
      <ul>
        <li onClick={() => handleMenu('/mon-equipe')}>
          <TbUsersGroup size={30} color='#0C0B20' />
          Mon Équipe
        </li>
        <li onClick={() => handleMenu('/mon-compte')}>
          <TbUserCircle size={30} color='#0C0B20' />
          Mon Compte
        </li>
        <li onClick={() => handleMenu('/contact')}>
          <TbPhone size={30} color='#0C0B20' />
          Contact
        </li>
      </ul>
    </motion.div>
  );
}

export default MenuBottom;
