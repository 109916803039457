import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TbChevronLeft, TbSettings, TbPencil  } from "react-icons/tb";
import './AdminTournamentDetails.css';
import TournamentActionsPopup from '../TournamentActionspopup';
import TeamEditPopup from './TeamEditPopup';

function AdminTournamentDetails() {
    const { tournamentId } = useParams();
    const [tournament, setTournament] = useState(null);
    const [matches, setMatches] = useState([]); 
    const [teams, setTeams] = useState([]);  
    const [searchTerm, setSearchTerm] = useState(''); 
    const [filterStatus, setFilterStatus] = useState('');
    const [showSettings, setShowSettings] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [activeTab, setActiveTab] = useState('matches');
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(null); // Pour l'équipe sélectionnée pour la modification
    const navigate = useNavigate(); 

    const fetchTournamentMatches = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/tournament/${tournamentId}/matches`);

            if (response.status === 404) {
                setMatches([]);
                return;
            }

            if (!response.ok) {
                throw new Error('Failed to fetch matches');
            }

            const data = await response.json();
            
            const matchPromises = data.map(async match => {
                const homeTeamResponse = await fetch(`${process.env.REACT_APP_API_URL}/teams/${match.homeTeam}`);
                const awayTeamResponse = await fetch(`${process.env.REACT_APP_API_URL}/teams/${match.awayTeam}`);
                const homeTeam = await homeTeamResponse.json();
                const awayTeam = await awayTeamResponse.json();
                
                return {
                    ...match,
                    homeTeamName: homeTeam.name,
                    awayTeamName: awayTeam.name,
                };
            });

            const matchesWithTeamNames = await Promise.all(matchPromises);
            setMatches(matchesWithTeamNames);
        } catch (error) {
            console.error('Error fetching matches:', error);
        }
    }, [tournamentId]);

    const fetchTournamentTeams = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/tournament/${tournamentId}/teams`);

            if (response.status === 404) {
                setTeams([]);
                return;
            }

            if (!response.ok) {
                throw new Error('Failed to fetch teams');
            }

            const teamsData = await response.json();

            const teamPromises = teamsData.map(async team => {
                const teamResponse = await fetch(`${process.env.REACT_APP_API_URL}/teams/${team.id}/admin`);
                const teamDetails = await teamResponse.json();

                return {
                    ...team,
                    ...teamDetails,
                };
            });

            const teamsWithDetails = await Promise.all(teamPromises);
            setTeams(teamsWithDetails);
        } catch (error) {
            console.error('Error fetching teams:', error);
        }
    }, [tournamentId]);

    useEffect(() => {
        const fetchTournamentDetails = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/tournament/${tournamentId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch tournament details');
                }
                const data = await response.json();
                setTournament(data);
            } catch (error) {
                console.error('Error fetching tournament details:', error);
            }
        };

        const checkUserRole = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/check-role`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to check user role');
                }

                const data = await response.json();
                setIsSuperAdmin(data.role === 'superadmin');
            } catch (error) {
                console.error('Error checking user role:', error);
            }
        };

        fetchTournamentDetails();
        fetchTournamentMatches();
        fetchTournamentTeams();
        checkUserRole();
    }, [tournamentId, fetchTournamentMatches, fetchTournamentTeams]);

    if (!tournament) {
        return <div>Loading...</div>;
    }

    const filteredMatches = matches.filter(match => 
        (match.homeTeamName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        match.awayTeamName.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (filterStatus ? match.statusMatch === filterStatus : true)
    );

    const handleMatchClick = (matchId) => {
        navigate(`/admin/tournament/${tournamentId}/matches/${matchId}`);
    };

    const formatDate = (dateString) => {
        if (!dateString) {
            return "À venir";
        }
        const date = new Date(dateString);
        return !isNaN(date.getTime()) ? date.toLocaleString() : "À venir";
    };

    const handleEditTeam = (team) => {
        setSelectedTeam(team);
        setShowEditPopup(true);
    };

    const handleSaveTeam = () => {
        fetchTournamentTeams(); // Rafraîchir la liste des équipes après la modification
        setShowEditPopup(false);
    };

    const renderMatches = () => (
        <>
            <div className="filterButtons">
                <button 
                    className={`filterButton ${filterStatus === 'à venir' ? 'active' : ''}`}
                    onClick={() => setFilterStatus('à venir')}
                >
                    À venir
                </button>
                <button 
                    className={`filterButton ${filterStatus === 'terminé' ? 'active' : ''}`}
                    onClick={() => setFilterStatus('terminé')}
                >
                    Terminé
                </button>
                <button 
                    className={`filterButton ${filterStatus === '' ? 'active' : ''}`}
                    onClick={() => setFilterStatus('')}
                >
                    Tous
                </button>
            </div>

            <div className="matchesList">
                <h3>Liste des matchs</h3>
                {filteredMatches.length > 0 ? (
                    filteredMatches.map(match => (
                        <div 
                            key={match.id} 
                            className="matchItem" 
                            onClick={() => handleMatchClick(match.id)}
                        >
                            <h3>{match.homeTeamName} vs {match.awayTeamName}</h3>
                            <p>Date: {formatDate(match.matchDate)}</p>
                            <p>Status: {match.statusMatch}</p>
                        </div>
                    ))
                ) : (
                    <p>Aucun match trouvé.</p>
                )}
            </div>
        </>
    );

    const renderTeams = () => (
        <div className="teamsList">
            <h3>Liste des équipes</h3>
            {teams.length > 0 ? (
                teams.map(team => (
                    <div key={team.id} className="teamItem">
                        <h3>{team.name}</h3>
                        <p>Nombre de joueurs: {team.players ? team.players.length : 0}</p>
                        <button className="editButton" onClick={() => handleEditTeam(team)}><TbPencil size={24}/></button>
                    </div>
                ))
            ) : (
                <p>Aucune équipe trouvée.</p>
            )}
        </div>
    );

    return (
        <div className='adminTournamentDetailsContainer'>
            <div className="backButtonContainer">
                <button className="backButton" onClick={() => navigate(-1)}>
                    <TbChevronLeft />
                </button>
                <h2>{tournament.name}</h2>
                {isSuperAdmin && (
                    <TbSettings size={32}
                        className="settingsIcon" 
                        onClick={() => setShowSettings(!showSettings)} 
                    />
                )}
            </div>

            {showSettings && (
                <div className="tournamentSettings">
                    <TournamentActionsPopup 
                        tournament={tournament} 
                        onClose={() => setShowSettings(false)} 
                        refreshMatches={fetchTournamentMatches} 
                    />
                </div>
            )}

            <div className="tabsContainer">
                <button 
                    className={`tabButton ${activeTab === 'matches' ? 'active' : ''}`} 
                    onClick={() => setActiveTab('matches')}
                >
                    Matchs
                </button>
                <button 
                    className={`tabButton ${activeTab === 'teams' ? 'active' : ''}`} 
                    onClick={() => setActiveTab('teams')}
                >
                    Équipes
                </button>
            </div>

            <div className="contentContainer">
                {activeTab === 'matches' ? renderMatches() : renderTeams()}
            </div>

            {showEditPopup && selectedTeam && (
                <TeamEditPopup 
                    team={selectedTeam} 
                    onClose={() => setShowEditPopup(false)} 
                    onSave={handleSaveTeam}
                    onRefreshTeams={fetchTournamentTeams}
                />
            )}
        </div>
    );
}

export default AdminTournamentDetails;