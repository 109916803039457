// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
  background: #0C0B20;
  background-size: cover;
  padding-bottom: 120px;
  padding-top: 64px;
  position: relative;
  align-items: center;
}

.adminContainer > div{
  position: inherit;
  max-width: 500px;
  width: 100%;
}

 

  .cancel-button {
    background-color: #D32C2C;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
  }
  
  .adminLogout{
    position: absolute;
    right: 16px;
    display: flex;
    justify-content: end;
    gap: 8px;
    color: rgb(255, 83, 83);
    top: -32px;
  }`, "",{"version":3,"sources":["webpack://./src/assets/Styles/AdminDashboard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,sBAAsB;EACtB,qBAAqB;EACrB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,WAAW;AACb;;;;EAIE;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,oBAAoB;IACpB,QAAQ;IACR,uBAAuB;IACvB,UAAU;EACZ","sourcesContent":[".adminContainer{\r\n  display: flex;\r\n  flex-direction: column;\r\n  width: 100%;\r\n  max-width: 100%;\r\n  min-height: 100vh;\r\n  background: #0C0B20;\r\n  background-size: cover;\r\n  padding-bottom: 120px;\r\n  padding-top: 64px;\r\n  position: relative;\r\n  align-items: center;\r\n}\r\n\r\n.adminContainer > div{\r\n  position: inherit;\r\n  max-width: 500px;\r\n  width: 100%;\r\n}\r\n\r\n \r\n\r\n  .cancel-button {\r\n    background-color: #D32C2C;\r\n    color: white;\r\n    border: none;\r\n    padding: 10px;\r\n    border-radius: 5px;\r\n    width: 100%;\r\n    font-size: 16px;\r\n    cursor: pointer;\r\n    text-align: center;\r\n  }\r\n  \r\n  .adminLogout{\r\n    position: absolute;\r\n    right: 16px;\r\n    display: flex;\r\n    justify-content: end;\r\n    gap: 8px;\r\n    color: rgb(255, 83, 83);\r\n    top: -32px;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
