// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminNavContainer {
  display: flex;
  padding: 16px 20px;
  width: 100%;
}

.adminNavTabs{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    gap: 16px;
}

.adminNavItem{
    font-family: Lato;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    cursor: pointer;
    position: relative;
    margin: 0;
    transition: all ease-in-out 0.4s;
}

.adminNavItem.active{
    color: #C3D32C;
    font-size: 18px;
    margin-bottom: 8px;
    margin-top: -8px;
}`, "",{"version":3,"sources":["webpack://./src/components/AdminDashboard/AdminNav.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,WAAW;AACb;;AAEA;IACI,aAAa;IACb,WAAW;IACX,6BAA6B;IAC7B,SAAS;AACb;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,gCAAgC;AACpC;;AAEA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".adminNavContainer {\r\n  display: flex;\r\n  padding: 16px 20px;\r\n  width: 100%;\r\n}\r\n\r\n.adminNavTabs{\r\n    display: flex;\r\n    width: 100%;\r\n    justify-content: space-evenly;\r\n    gap: 16px;\r\n}\r\n\r\n.adminNavItem{\r\n    font-family: Lato;\r\n    font-size: 18px;\r\n    font-weight: 700;\r\n    color: #ffffff;\r\n    cursor: pointer;\r\n    position: relative;\r\n    margin: 0;\r\n    transition: all ease-in-out 0.4s;\r\n}\r\n\r\n.adminNavItem.active{\r\n    color: #C3D32C;\r\n    font-size: 18px;\r\n    margin-bottom: 8px;\r\n    margin-top: -8px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
