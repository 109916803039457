import React, { useState, useEffect } from 'react';
import '../assets/Styles/MonEquipe.css';
import MonEquipeBanner from '../components/MonEquipe/MonEquipeBanner';
import PlayerGrid from '../components/MonEquipe/PlayerGrid';
import PlayerPopup from '../components/MonEquipe/PlayerPopup';
import PlayerPopupCreate from '../components/MonEquipe/PlayerPopupCreate';

function MonEquipe() {
  // États pour stocker les données de l'équipe, les joueurs, et les informations sur les joueurs sélectionnés
  const [team, setTeam] = useState({ id: null, name: '', logo: '', captainId: null });
  const [players, setPlayers] = useState([]);
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  // Effet pour récupérer les données de l'équipe et des joueurs associés lorsque le composant est monté
  useEffect(() => {
    const fetchTeam = async () => {
      const token = localStorage.getItem('token'); // Récupérer le token depuis le localStorage

      try {
        // Requête pour obtenir les informations de l'équipe de l'utilisateur
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user/team`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch team');
        }

        const teamData = await response.json();
        setTeam({
          id: teamData.id,
          name: teamData.name,
          logo: teamData.logo || 'uploads/defaultTeamImage.jpg',
          captainId: teamData.captainId,
        });

        // Requête pour obtenir les joueurs associés à l'équipe
        const playersResponse = await fetch(`${process.env.REACT_APP_API_URL}/teams/${teamData.id}/players`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!playersResponse.ok) {
          throw new Error('Failed to fetch players');
        }

        const playersData = await playersResponse.json();
        setPlayers(playersData);
        console.log(playersData);

      } catch (error) {
        console.error('Error fetching team or players:', error);
      }
    };

    fetchTeam();
  }, []);

  // Gestion du clic sur un joueur existant pour le sélectionner
  const handlePlayerClick = (playerId) => {
    setSelectedPlayerId(playerId);
    const selected = players.find(player => player.id === playerId) || null;
    setSelectedPlayer(selected);
    setIsCreating(false);
  };

  // Gestion du clic pour ajouter un nouveau joueur
  const handleAddPlayerClick = () => {
    setSelectedPlayerId(null);
    setSelectedPlayer(null);
    setIsCreating(true);
  };

  // Gestion de la fermeture de la popup (modale)
  const handleClosePopup = () => {
    setSelectedPlayerId(null);
    setSelectedPlayer(null);
    setIsCreating(false);
  };

  // Gestion de la sauvegarde des modifications d'un joueur existant
  const handleSavePlayer = async (playerData) => {
    const token = localStorage.getItem('token'); // Récupérer le token depuis le localStorage

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/players/${selectedPlayerId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(playerData)
      });

      if (!response.ok) {
        throw new Error('Failed to save player');
      }

      const updatedPlayer = await response.json();
      setPlayers(players.map(player => player.id === selectedPlayerId ? updatedPlayer : player));
    } catch (error) {
      console.error('Error saving player:', error);
    }

    handleClosePopup();
  };

  // Gestion de la création d'un nouveau joueur
  const handleCreatePlayer = async (playerData) => {
    const token = localStorage.getItem('token'); // Récupérer le token depuis le localStorage

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/players`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ ...playerData, teamId: team.id })
      });

      if (!response.ok) {
        throw new Error('Failed to create player');
      }

      const newPlayer = await response.json();
      setPlayers([...players, newPlayer]);
    } catch (error) {
      console.error('Error creating player:', error);
    }

    handleClosePopup();
  };

  // Gestion de la suppression d'un joueur
  const handleDeletePlayer = async (playerId) => {
    const token = localStorage.getItem('token'); // Récupérer le token depuis le localStorage

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/players/${playerId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to delete player');
      }

      setPlayers(players.filter(player => player.id !== playerId));
    } catch (error) {
      console.error('Error deleting player:', error);
    }

    handleClosePopup();
  };

  return (
    <div className='monEquipeContainer'>
      <MonEquipeBanner />
      <div className='monEquipeLogoName'>
        <img src={`${process.env.REACT_APP_API_URL}/${team.logo}`} alt={team.name} />
        <p>{team.name}</p>
      </div>
      <PlayerGrid players={players} onPlayerClick={handlePlayerClick} onAddPlayerClick={handleAddPlayerClick} />
      {selectedPlayerId !== null && !isCreating && (
        <PlayerPopup
          player={selectedPlayer}
          onClose={handleClosePopup}
          onSave={handleSavePlayer}
          onDelete={handleDeletePlayer}
          isCaptain={selectedPlayerId === team.captainId}
        />
      )}
      {isCreating && (
        <PlayerPopupCreate
          onClose={handleClosePopup}
          onCreate={handleCreatePlayer}
        />
      )}
    </div>
  );
}

export default MonEquipe;
