import React, { useState } from 'react';
import './RegisterStep2.css';
import { useNavigate } from 'react-router-dom';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

function RegisterStep2({ previousStep, handleChange, formData, clientSecret }) {
  const [invalidFields, setInvalidFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // État pour le message d'erreur
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    handleChange(name, type === 'checkbox' ? checked : value);

    if (invalidFields[name]) {
      setInvalidFields({
        ...invalidFields,
        [name]: false,
      });
    }
  };

  const validateForm = () => {
    let formInvalidFields = {};
    if (!formData.firstName) formInvalidFields.firstName = true;
    if (!formData.lastName) formInvalidFields.lastName = true;
    if (!formData.postalCode) formInvalidFields.postalCode = true;
    if (!formData.city) formInvalidFields.city = true;
    if (!formData.address) formInvalidFields.address = true;
    if (!formData.email) formInvalidFields.email = true;
    if (!formData.termsAccepted) formInvalidFields.termsAccepted = true;

    setInvalidFields(formInvalidFields);
    return Object.keys(formInvalidFields).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);
    setErrorMessage(''); // Réinitialiser le message d'erreur avant de commencer le paiement

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment-confirmation`,
      },
      redirect: "if_required", // Gérer la réponse directement sans redirection automatique
    });

    if (error) {
      console.error('Payment failed:', error.message);
      setErrorMessage('Échec du paiement. Veuillez réessayer.'); // Définir le message d'erreur
      setLoading(false);

      // Effacer le message d'erreur après 5 secondes
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);

      return;
    }

    if (paymentIntent && paymentIntent.status === "succeeded") {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append('firstName', formData.firstName);
        formDataToSend.append('lastName', formData.lastName);
        formDataToSend.append('clubName', formData.clubName);
        formDataToSend.append('phone', formData.phone);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('password', formData.password);
        formDataToSend.append('termsAccepted', formData.termsAccepted);
        formDataToSend.append('tournament', formData.tournament);
        formDataToSend.append('postalCode', formData.postalCode);
        formDataToSend.append('city', formData.city);
        formDataToSend.append('address', formData.address);

        if (formData.file) {
          formDataToSend.append('file', formData.file);
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/register`, {
          method: 'POST',
          body: formDataToSend,
        });

        const data = await response.json();

        if (response.ok) {
          console.log('Registration successful:', data);
          navigate('/login'); // Rediriger vers la page de connexion
        } else {
          console.error('Registration failed:', data);
        }
      } catch (error) {
        console.error('Error during registration:', error);
      }
    }

    setLoading(false);
  };

  return (
    <div className="registerStep2Container">
      <p>Informations de facturation</p>
      <form onSubmit={handleSubmit}>
        <div className="inputGroup">
          <div className="inputWithIcon">
            <input
              type="text"
              name="firstName"
              placeholder="Nom"
              value={formData.firstName || ''}
              onChange={handleInputChange}
              className={invalidFields.firstName ? 'invalid' : ''}
            />
          </div>
          <div className="inputWithIcon">
            <input
              type="text"
              name="lastName"
              placeholder="Prénom"
              value={formData.lastName || ''}
              onChange={handleInputChange}
              className={invalidFields.lastName ? 'invalid' : ''}
            />
          </div>
        </div>
        <div className="inputGroup">
          <div className="inputWithIcon">
            <input
              type="text"
              name="postalCode"
              placeholder="CP"
              value={formData.postalCode || ''}
              onChange={handleInputChange}
              className={invalidFields.postalCode ? 'invalid' : ''}
            />
          </div>
          <div className="inputWithIcon">
            <input
              type="text"
              name="city"
              placeholder="Ville"
              value={formData.city || ''}
              onChange={handleInputChange}
              className={invalidFields.city ? 'invalid' : ''}
            />
          </div>
        </div>
        <div className="inputWithIcon">
          <input
            type="text"
            name="address"
            placeholder="Adresse postal"
            value={formData.address || ''}
            onChange={handleInputChange}
            className={invalidFields.address ? 'invalid' : ''}
          />
        </div>
        <div className="inputWithIcon">
          <input
            type="email"
            name="email"
            placeholder="Adresse e-mail"
            value={formData.email || ''}
            onChange={handleInputChange}
            className={invalidFields.email ? 'invalid' : ''}
          />
        </div>

        <div className="amount">
          <h2>Montant : 105.00€</h2>
        </div>

        {/* Formulaire de paiement Stripe */}
        {clientSecret && (
          <div className="paymentContainer">
            <p>Paiement</p>
            {/* Affichage du message d'erreur */}
            {errorMessage && <div className="payment-error-message">{errorMessage}</div>}
            <PaymentElement />
            <button type="submit" disabled={loading}>
              {loading ? "Paiement en cours ..." : "Payer"}
            </button>
          </div>
        )}

        <button type="button" onClick={previousStep} disabled={loading}>Retour</button>
      </form>
    </div>
  );
}

export default RegisterStep2;
