import React, { useState } from 'react';
import { TbUser, TbUsersGroup, TbMail, TbMessageCircle, TbPhone, TbWorld } from "react-icons/tb";
import '../assets/Styles/Contact.css';
import DetailsBanner from '../components/Tournament/DetailsBanner';
import { useNavigate } from 'react-router-dom';
import PartnersSlider from '../components/UI/PartnersSlider';

function Contact() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    clubName: '',
    email: '',
    message: '',
    termsAccepted: false,
  });

  const [responseMessage, setResponseMessage] = useState(''); // Pour afficher un message de succès ou d'erreur
  const [isSubmitting, setIsSubmitting] = useState(false); // Pour gérer l'état de soumission du formulaire

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setResponseMessage('');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de l\'envoi du formulaire');
      }

      const result = await response.json();
      setResponseMessage('Message envoyé avec succès !');
      console.log('Form data submitted:', result);

      // Réinitialiser le formulaire après soumission réussie
      setFormData({
        firstName: '',
        lastName: '',
        clubName: '',
        email: '',
        message: '',
        termsAccepted: false,
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      setResponseMessage('Erreur lors de l\'envoi du message. Veuillez réessayer.');
    } finally {
      setIsSubmitting(false);
      // Effacer le message après 5 secondes
      setTimeout(() => {
        setResponseMessage('');
      }, 5000);
    }
  };

  return (
    <div className="contactContainer">
      <DetailsBanner/>
      <PartnersSlider/>
      <div className='contactHeader'>
        <p>Prends rendez-vous et réserve ton terrain maintenant.</p>
        <p>Réservation par téléphone ou email uniquement.</p>
      </div>
      <div className="contactDetails">
        <p>Coordonnées</p>
        <p><TbPhone size={24} /> 0693 90 27 68 / 0263 11 94 11</p>
        <p><TbMail size={24} /> contact@ocorner.re</p>
        <p><TbWorld size={24} /><a href="https://www.ocorner.re">https://www.ocorner.re</a></p>
      </div>
      <form className="contactForm" onSubmit={handleSubmit}>
        <div className="inputGroup">
          <div className="inputWithIcon">
            <TbUser size={24} />
            <input
              type="text"
              name="firstName"
              placeholder="Nom"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="inputWithIcon">
            <TbUser size={24} />
            <input
              type="text"
              name="lastName"
              placeholder="Prénom"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="inputWithIcon">
          <TbUsersGroup size={24} />
          <input
            type="text"
            name="clubName"
            placeholder="Nom du club"
            value={formData.clubName}
            onChange={handleChange}
          />
        </div>
        <div className="inputWithIcon">
          <TbMail size={24} />
          <input
            type="email"
            name="email"
            placeholder="Adresse e-mail"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="inputWithIcon">
          <TbMessageCircle size={24} />
          <textarea
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </div>
        <div className="termsContainer">
          <input
            type="checkbox"
            name="termsAccepted"
            checked={formData.termsAccepted}
            onChange={handleChange}
            required
          />
          <p>J'ai lu et j'accepte les conditions générales de l'application <a href="#conditions">OPEN CHALLENGE</a></p>
        </div>
        <button className="sendButton" type="submit" disabled={isSubmitting}>ENVOYER</button>
      </form>
      {responseMessage && <p className="responseMessage">{responseMessage}</p>}
    </div>
  );
}

export default Contact;
