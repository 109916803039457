// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addAdminContainer{
    display: flex;
    width: 100%;
    background: white;
    padding: 16px;
    flex-direction: column;
    border-radius: 16px;
}

.addAdminForm{
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.addAdminContainer h2{
    color: #c3d32c;
}

.addAdminForm .formGroup{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.addAdminForm .formGroup input{
    padding: 8px 16px;
    border-radius: 24px;
    border: 1px solid rgba(110, 110, 110, 0.521);
    margin-top: 8px;
    font-size: 16px;
}

.addAdminForm button{
    padding: 16px;
    border-radius: 24px;
    border: none;
    background-color: #c3d32c;
    font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/AdminDashboard/AddAdminForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,4CAA4C;IAC5C,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,yBAAyB;IACzB,eAAe;AACnB","sourcesContent":[".addAdminContainer{\r\n    display: flex;\r\n    width: 100%;\r\n    background: white;\r\n    padding: 16px;\r\n    flex-direction: column;\r\n    border-radius: 16px;\r\n}\r\n\r\n.addAdminForm{\r\n    margin-top: 16px;\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 8px;\r\n}\r\n\r\n.addAdminContainer h2{\r\n    color: #c3d32c;\r\n}\r\n\r\n.addAdminForm .formGroup{\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 100%;\r\n}\r\n\r\n.addAdminForm .formGroup input{\r\n    padding: 8px 16px;\r\n    border-radius: 24px;\r\n    border: 1px solid rgba(110, 110, 110, 0.521);\r\n    margin-top: 8px;\r\n    font-size: 16px;\r\n}\r\n\r\n.addAdminForm button{\r\n    padding: 16px;\r\n    border-radius: 24px;\r\n    border: none;\r\n    background-color: #c3d32c;\r\n    font-size: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
