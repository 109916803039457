import React, { useState, useEffect } from 'react';
import "../assets/Styles/Register.css";
import RegisterBanner from '../components/Register/RegisterBanner';
import RegisterStep1 from '../components/Register/RegisterStep1';
import RegisterStep2 from '../components/Register/RegisterStep2';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// Clé publique de Stripe
const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY; // Utilisez le préfixe REACT_APP_
const stripePromise = loadStripe(stripePublicKey);

function Register() {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    clubName: '',
    phone: '',
    email: '',
    password: '',
    termsAccepted: false,
    file: null,
    tournament: '',
    postalCode: '',
    city: '',
    address: '',
    cardNumber: '',
    expiryDate: '',
    cvv: ''
  });

  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;

    // Récupérer le clientSecret depuis le backend
    fetch(`${apiUrl}/create-payment-intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount: 10500 }), // Montant en centimes
    })
      .then(res => res.json())
      .then(data => {
        console.log('Received clientSecret:', data.clientSecret);
        setClientSecret(data.clientSecret);
      })
      .catch(error => console.error('Error:', error));
  }, []);

  const nextStep = () => {
    setCurrentStep(prevStep => prevStep + 1);
  };

  const previousStep = () => {
    setCurrentStep(prevStep => prevStep - 1);
  };

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
    appearance:{
      theme: 'night',
      labels: 'floating'
    }
  };
  return (
    <div className="registerContainer">
      <RegisterBanner />

      {currentStep === 1 && (
        <RegisterStep1
          nextStep={nextStep}
          handleChange={handleChange}
          formData={formData}
        />
      )}

      {currentStep === 2 && clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <RegisterStep2
            previousStep={previousStep}
            nextStep={nextStep}
            handleChange={handleChange}
            formData={formData}
            clientSecret={options.clientSecret }
          />
        </Elements>
      )}
    </div>
  );
}

export default Register;
