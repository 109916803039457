import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './TournamentList.css';

function TournamentList({ tournaments, onEdit, onDelete }) {
  const navigate = useNavigate();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  // Fonction pour vérifier le rôle de l'utilisateur
  useEffect(() => {
    const checkUserRole = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/check-role`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user role');
        }

        const data = await response.json();
        setIsSuperAdmin(data.role === 'superadmin'); // Met à jour l'état si le rôle est superadmin
      } catch (error) {
        console.error('Error fetching user role:', error);
      }
    };

    checkUserRole();
  }, []);

  // Fonction pour formater la date
  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', options);
  };

  // Fonction pour gérer le clic sur un tournoi
  const handleTournamentClick = (tournamentId) => {
    navigate(`/admin/tournament/${tournamentId}`);
  };

  return (
    <div className="tournament-list">
      <h2>Liste des tournois</h2>
      <ul>
        {tournaments.map((tournament) => (
          <li 
            key={tournament.id} 
            className="tournament-item" 
            onClick={() => handleTournamentClick(tournament.id)}
          >
            <div className="tournament-details">
              <h3>{tournament.name}</h3>
              <p>Date: {formatDate(tournament.date)}</p>
              <p>Lieu: {tournament.location}</p>
            </div>
            {isSuperAdmin && ( // Affiche les boutons uniquement si l'utilisateur est superadmin
              <div className="tournament-actions">
                <button 
                  onClick={(e) => { 
                    e.stopPropagation(); 
                    onEdit(tournament); 
                  }} 
                  className="edit-button"
                >
                  Éditer
                </button>
                <button 
                  onClick={(e) => { 
                    e.stopPropagation(); 
                    onDelete(tournament.id); 
                  }} 
                  className="delete-button"
                >
                  Supprimer
                </button>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default TournamentList;
