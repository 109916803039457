import React, { useEffect, useState } from "react";
import "./MatchesCard.css";
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr'; // Pour gérer les dates en français
import { TbCrown } from "react-icons/tb";


function MatchesCard({ match }) {

  let formattedDate = '-';
  
  if (match.matchDate) {
    // Crée un objet Date en ajoutant "T" entre la date et l'heure pour compatibilité ISO
    const date = new Date(match.matchDate.replace(' ', 'T'));

    // Vérifiez si la date est valide
    if (!isNaN(date.getTime())) {
      formattedDate = format(date, "dd/MM 'à' HH:mm", { locale: fr });
    }
  }

  const [awayTeam, setAwayTeam] = useState([]);
  const [homeTeam, setHomeTeam] = useState([]);
  const homeTeamId = match.homeTeam;
  const awayTeamId = match.awayTeam;

  useEffect(() => {
    // Fonction pour récupérer les détails de l'équipe à domicile
    const fetchHomeTeam = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/teams/${homeTeamId}`);
        const data = await response.json();

        if (response.ok) {
          setHomeTeam(data || []); // S'assurer que data est bien reçu
        } else {
          console.error('Failed to fetch home team:', data.message);
          setHomeTeam([]); // En cas d'erreur, définir homeTeam comme tableau vide
        }
      } catch (error) {
        console.error('Error fetching home team:', error);
        setHomeTeam([]); // En cas d'erreur, définir homeTeam comme tableau vide
      }
    };

    fetchHomeTeam();
  }, [homeTeamId]);

  useEffect(() => {
    // Fonction pour récupérer les détails de l'équipe à l'extérieur
    const fetchAwayTeam = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/teams/${awayTeamId}`);
        const data = await response.json();

        if (response.ok) {
          setAwayTeam(data || []); // S'assurer que data est bien reçu
        } else {
          console.error('Failed to fetch away team:', data.message);
          setAwayTeam([]); // En cas d'erreur, définir awayTeam comme tableau vide
        }
      } catch (error) {
        console.error('Error fetching away team:', error);
        setAwayTeam([]); // En cas d'erreur, définir awayTeam comme tableau vide
      }
    };

    fetchAwayTeam();
  }, [awayTeamId]);

  if (!awayTeam || !homeTeam) {
    return (
      <div>
        {/* Afficher un état de chargement si les données des équipes ne sont pas encore disponibles */}
      </div>
    );
  }

  return (
    <div className="matchesCardContainer">
      <div className="matchesCardInfos">
        <p className="matchesField">{match.fieldName}</p>
        <p className="matchesStatus">{match.statusMatch}</p>
        <p className="matchesDate">{formattedDate}</p>
      </div>
      <div className="matchesScoreTeams">
        <div className="homeTeam">
          <img className="homeTeamImage" src={`${process.env.REACT_APP_API_URL}/${homeTeam.logo}`} alt="" />
          <p className="homeTeamName">{homeTeam.name}</p>
        </div>
        <div className="score">
          <p className="homeScore">{match.homeScore}</p>
          <span>-</span>
          <p className="awayScore">{match.awayScore}</p>
        </div>
        <div className="awayTeam">
          <img className="awayTeamImage" src={`${process.env.REACT_APP_API_URL}/${awayTeam.logo}`} alt="" />
          <p className="awayTeamName">{awayTeam.name}</p>
        </div>
      </div>
      {/* Affichage du meilleur buteur s'il est présent */}
      {match.topScorer && (
        <p className="topScorer"><TbCrown size={24} color="#FFD700"/> Meilleur buteur : N°{match.topScorer}</p>
      )}
    </div>
  );
}

export default MatchesCard;
