import React, { useState, useEffect } from 'react';
import './RegisterStep1.css';
import { TbUser, TbLock, TbMail, TbPhone, TbShieldCheck } from "react-icons/tb";
import defaultTeamImage from '../../assets/Images/defaultTeamImage.jpg';

function RegisterStep1({ nextStep, handleChange, formData }) {
  const [invalidFields, setInvalidFields] = useState({});
  const [tournaments, setTournaments] = useState([]);

  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/tournaments`);
        const data = await response.json();
        setTournaments(data);
      } catch (error) {
        console.error('Error fetching tournaments:', error);
      }
    };

    fetchTournaments();
  }, []);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.size <= 10 * 1024 * 1024) { // 10 MB
      handleChange('file', selectedFile);
      setInvalidFields({
        ...invalidFields,
        file: false,
      });
    } else {
      handleChange('file', null);
      setInvalidFields({
        ...invalidFields,
        file: true,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    handleChange(name, type === 'checkbox' ? checked : value);

    if (invalidFields[name]) {
      setInvalidFields({
        ...invalidFields,
        [name]: false,
      });
    }
  };

  const validateForm = () => {
    let formInvalidFields = {};
    if (formData.file && formData.file.size > 10 * 1024 * 1024) formInvalidFields.file = true;
    if (!formData.firstName) formInvalidFields.firstName = true;
    if (!formData.lastName) formInvalidFields.lastName = true;
    if (!formData.clubName) formInvalidFields.clubName = true;
    if (!formData.phone) formInvalidFields.phone = true;
    if (!formData.email) formInvalidFields.email = true;
    if (!formData.password) formInvalidFields.password = true;
    if (!formData.termsAccepted) formInvalidFields.termsAccepted = true;
    if (!formData.tournament) formInvalidFields.tournament = true;

    setInvalidFields(formInvalidFields);
    return Object.keys(formInvalidFields).length === 0;
  };

  const handleNext = () => {
    if (validateForm()) {
      nextStep();
    }
  };

  return (
    <div className="registerStep1Container">
      <form>
        <div className={`blason ${invalidFields.file ? 'invalid' : ''}`}>
          <p>Ajoutez votre blason et montrer aux autres votre style <br />(10 mb max)</p>
          <div className="fileUploadContainer">
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              onChange={handleFileChange}
            />
            <label htmlFor="fileInput" className="fileLabel">
              <div className="fileCircle">
                {formData.file ? (
                  <img
                    src={URL.createObjectURL(formData.file)}
                    alt="Team Blason"
                    className="uploadedImage"
                  />
                ) : (
                  <img
                    src={defaultTeamImage}
                    alt="Default Team Blason"
                    className="uploadedImage"
                  />
                )}
              </div>
            </label>
          </div>
        </div>
        <div className="inputGroup">
          <div className="selectTournament">
            <label htmlFor="tournament">Sélectionnez un tournoi</label>
            <select
              name="tournament"
              value={formData.tournament}
              onChange={handleInputChange}
              className={invalidFields.tournament ? 'invalid' : ''}
            >
              <option value="">-</option>
              {tournaments.map(tournament => (
                <option key={tournament.id} value={tournament.id}>
                  {tournament.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="inputGroup">
          <div className="inputWithIcon">
            <TbUser size={24} />
            <input
              type="text"
              name="firstName"
              placeholder="Nom"
              value={formData.firstName}
              onChange={handleInputChange}
              className={invalidFields.firstName ? 'invalid' : ''}
            />
          </div>
          <div className="inputWithIcon">
            <TbUser size={24} />
            <input
              type="text"
              name="lastName"
              placeholder="Prénom"
              value={formData.lastName}
              onChange={handleInputChange}
              className={invalidFields.lastName ? 'invalid' : ''}
            />
          </div>
        </div>
        <div className="inputWithIcon">
          <TbShieldCheck size={24} />
          <input
            type="text"
            name="clubName"
            placeholder="Nom du club"
            value={formData.clubName}
            onChange={handleInputChange}
            className={invalidFields.clubName ? 'invalid' : ''}
            maxLength={10}
          />
        </div>
        <div className="inputWithIcon">
          <TbPhone size={24} />
          <input
            type="tel"
            name="phone"
            placeholder="Téléphone"
            value={formData.phone}
            onChange={handleInputChange}
            className={invalidFields.phone ? 'invalid' : ''}
          />
        </div>
        <div className="inputWithIcon">
          <TbMail size={24} />
          <input
            type="email"
            name="email"
            placeholder="Adresse e-mail"
            value={formData.email}
            onChange={handleInputChange}
            className={invalidFields.email ? 'invalid' : ''}
          />
        </div>
        <div className="inputWithIcon">
          <TbLock size={24} />
          <input
            type="password"
            name="password"
            placeholder="Mot de passe"
            value={formData.password}
            onChange={handleInputChange}
            className={invalidFields.password ? 'invalid' : ''}
          />
        </div>
        <div className={`termsContainer ${invalidFields.termsAccepted ? 'invalid' : ''}`}>
          <input
            type="checkbox"
            name="termsAccepted"
            checked={formData.termsAccepted}
            onChange={handleInputChange}
          />
          <p>J'ai lu et j'accepte les conditions générales de l'application <a href="#conditions">OPEN CHALLENGE</a></p>
        </div>
        <button type="button" onClick={handleNext}>Suivant</button>
      </form>
    </div>
  );
}

export default RegisterStep1;
