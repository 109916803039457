// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overviewMenuContainer {
    display: flex;
    padding: 16px 20px;
    width: 100%;
  }

  .overviewMenuTabs{
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  
  .overviewMenuItem {
    font-family: Lato;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    cursor: pointer;
    position: relative;
    margin: 0;
    transition: all ease-in-out 0.4s;
  }
  
  .overviewMenuItem.active {
    color: #C3D32C;
    font-size: 18px;
    margin-bottom: 8px;
    margin-top: -8px;
  }
  
  .overviewMenuItem.active::before {
    content: '';
    position: absolute;
    bottom: -5px; /* Adjust to move the highlight up/down */
    left: 0;
    right: 0;
    height: 4px;
    background-color: #C3D32C;
    box-shadow: 0 0 10px #C3D32C;
    border-radius: 2px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/UI/OverviewMenu.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,WAAW;IACX,8BAA8B;EAChC;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,gCAAgC;EAClC;;EAEA;IACE,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,YAAY,EAAE,yCAAyC;IACvD,OAAO;IACP,QAAQ;IACR,WAAW;IACX,yBAAyB;IACzB,4BAA4B;IAC5B,kBAAkB;EACpB","sourcesContent":[".overviewMenuContainer {\r\n    display: flex;\r\n    padding: 16px 20px;\r\n    width: 100%;\r\n  }\r\n\r\n  .overviewMenuTabs{\r\n    display: flex;\r\n    width: 100%;\r\n    justify-content: space-between;\r\n  }\r\n  \r\n  .overviewMenuItem {\r\n    font-family: Lato;\r\n    font-size: 18px;\r\n    font-weight: 700;\r\n    color: #ffffff;\r\n    cursor: pointer;\r\n    position: relative;\r\n    margin: 0;\r\n    transition: all ease-in-out 0.4s;\r\n  }\r\n  \r\n  .overviewMenuItem.active {\r\n    color: #C3D32C;\r\n    font-size: 18px;\r\n    margin-bottom: 8px;\r\n    margin-top: -8px;\r\n  }\r\n  \r\n  .overviewMenuItem.active::before {\r\n    content: '';\r\n    position: absolute;\r\n    bottom: -5px; /* Adjust to move the highlight up/down */\r\n    left: 0;\r\n    right: 0;\r\n    height: 4px;\r\n    background-color: #C3D32C;\r\n    box-shadow: 0 0 10px #C3D32C;\r\n    border-radius: 2px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
