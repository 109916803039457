import homeBg from '../../assets/Images/home_bg.png';
import rewardsImg from '../../assets/Images/rewards.png';
import partner_citysport from '../../assets/Images/city-sport.jpg';
import partner_easygym from '../../assets/Images/partner_easygym.jpg';
import partner_edena from '../../assets/Images/partner_edena.png';
import partner_phoenyx from '../../assets/Images/partner_phoenyx.jpg';
import partner_triaxereunion from '../../assets/Images/partner_triaxereunion.jpeg';
import aldo from '../../assets/Images/aldo.png';
import courir from '../../assets/Images/Courir.png';
import './PartnersSlider.css';

const PartnersSlider = () => {
  const images = [partner_citysport,partner_easygym,partner_edena,partner_phoenyx,partner_triaxereunion,aldo,courir];

  return (
    <div className="slider-container">
        <h2>Nos partenaires</h2>
      <div className="slider-content">
        {/* Dupliquer les images pour le défilement continu */}
        {images.concat(images).map((image, index) => (
          <div className="slider-item" key={index}>
            <img src={image} alt={`Partenaire ${index + 1}`} className="partner-logo" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PartnersSlider;
