// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registerBannerContainer{
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 16px 20px;
    position: relative;
    gap: 32px;
    justify-content: center;
    max-width: 500px;
}

.registerBannerContainer .backBtn{
    position: absolute;
    left: 16px;
}

.registerBannerContainer p{
    text-align: center;
    width: 80%;
    max-width: 80%;
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Register/RegisterBanner.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,SAAS;IACT,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".registerBannerContainer{\r\n    width: 100%;\r\n    min-height: 100px;\r\n    display: flex;\r\n    align-items: center;\r\n    position: relative;\r\n    padding: 16px 20px;\r\n    position: relative;\r\n    gap: 32px;\r\n    justify-content: center;\r\n    max-width: 500px;\r\n}\r\n\r\n.registerBannerContainer .backBtn{\r\n    position: absolute;\r\n    left: 16px;\r\n}\r\n\r\n.registerBannerContainer p{\r\n    text-align: center;\r\n    width: 80%;\r\n    max-width: 80%;\r\n    font-family: Lato;\r\n    font-size: 20px;\r\n    font-weight: 700;\r\n    color: white;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
