import React, { useState, useEffect } from "react";
import TournamentForm from "./TournamentForm";
import TournamentList from "./TournamentList";
import './TournamentNav.css';

function TournamentNav({ tournaments, onCreate, onEdit, onDelete, selectedTournament, setSelectedTournament }) {
  const [showForm, setShowForm] = useState(false); // État pour contrôler l'affichage du formulaire
  const [role, setRole] = useState(''); // État pour stocker le rôle de l'utilisateur

  // Effectuer une requête pour vérifier le rôle de l'utilisateur
  useEffect(() => {
    const checkUserRole = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/check-role`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user role');
        }

        const data = await response.json();
        setRole(data.role); // Met à jour l'état avec le rôle de l'utilisateur
      } catch (error) {
        console.error('Error fetching user role:', error);
      }
    };

    checkUserRole();
  }, []);

  const handleAddTournament = () => {
    setSelectedTournament(null); // Réinitialiser la sélection pour ajouter un nouveau tournoi
    setShowForm(true); // Afficher le formulaire
  };

  const handleEditTournament = (tournament) => {
    setSelectedTournament(tournament); // Sélectionner le tournoi à modifier
    setShowForm(true); // Afficher le formulaire
  };

  const handleFormClose = () => {
    setShowForm(false); // Masquer le formulaire
    setSelectedTournament(null); // Réinitialiser la sélection
  };

  return (
    <div className="tournamentNavContainer">
      {/* Affiche le bouton uniquement si l'utilisateur a le rôle superadmin */}
      {role === 'superadmin' && (
        <button onClick={handleAddTournament}>Ajouter un tournoi</button>
      )}

      <TournamentList
        tournaments={tournaments}
        onEdit={handleEditTournament} // Utiliser la fonction handleEditTournament pour afficher le formulaire lors de la modification
        onDelete={onDelete}
      />

      {showForm && (
        <TournamentForm
          onSubmit={onCreate}
          onEdit={onEdit}
          tournamentData={selectedTournament}
          clearSelectedTournament={handleFormClose} // Appeler handleFormClose pour fermer le formulaire
        />
      )}
    </div>
  );
}

export default TournamentNav;
